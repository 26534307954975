import React, { useState, useEffect } from "react";
import styles from "./access.module.css";
import { Link, useParams } from "react-router-dom";
import { AlertColor, Tooltip } from "@mui/material";
import axios from "axios";
import useSWR from "swr";
import StepSlider from "./Slider.js";
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import FetchData from "./ServerCall/FetchData";
import { NotificationDialog } from "./CommonFunctions/DialogComponents";
import {serverEndPoint} from "./ServerCall/EnvironmentVariables";

const fetcher = (url: string) =>
  axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-TENANT-ID": "silzila",
      },
    })
    .then((res) => res.data);

const Access = () => {
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [testMessage, setTestMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [modalContent, setModalContent] = useState<any>("User");
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [addedUserIds, setAddedUserIds] = useState<any>([]);
  const [editedUserIds, setEditedUserIds] = useState<any>([]);
  const { parentId } = useParams();
  const [originalAccessList, setOriginalAccessList] = useState<any>([]);
  const [revertedRows, setRevertedRows] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [isReverted, setIsReverted] = useState<any>(false);
  const [customPermissions, setCustomPermissions] = useState<any>({});
  const [saveMessage, setSaveMessage] = useState(false);
  const [modifiedUsers, setModifiedUsers] = useState<any>([]);

  const id_parent = localStorage.getItem("parentWorkspaceId");

  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate(-1);
  };

  let roles: any[] = [
    { id: 3, value: "owner", displayValue: "Owner" },
    { id: 4, value: "moderator", displayValue: "Moderator" },
    { id: 5, value: "creator", displayValue: "Creator" },
    { id: 7, value: "viewer", displayValue: "Viewer" },
    { id: 6, value: "custom_creator", displayValue: "Custom Creator" },
  ];

  const { data: workspaces } = useSWR(
    `${serverEndPoint}workspace`,
    fetcher
  );
  const { data: accessList = [], mutate: mutateAccessList } = useSWR(
    `${serverEndPoint}workspace/access/${parentId}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const { data: workspaceContent } = useSWR(
    `${serverEndPoint}workspace/${parentId}`,
    fetcher
  );
  const { data: parentWorkspaceContent } = useSWR(
    `${serverEndPoint}workspace/${id_parent}`,
    fetcher
  );

  let parentWorkspace = workspaces?.find(
    (workspace: any) => workspace.id === parentId
  );
  if (!parentWorkspace) {
    parentWorkspace = parentWorkspaceContent?.find(
      (workspace: any) => workspace.id === parentId
    );
  }
  const parentWorkspaceName = parentWorkspace?.name || "";
  const ownerName = parentWorkspace?.createdBy || "";
  const { data: modalData = [] } = useSWR(
    isModalOpen
      ? `${serverEndPoint}access/user-groups/workspace?workspaceId=${parentId}`
      : null,
    fetcher
  );
  const handleNewButtonClick = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (accessList.length > 0 && originalAccessList.length === 0) {
      setOriginalAccessList(JSON.parse(JSON.stringify(accessList)));
      const permissions: any = {};
      accessList.forEach((access: any) => {
        if (access.customPermissions && access.customPermissions.length > 0) {
          permissions[access.id] = {};
          access.customPermissions.forEach((permission: any) => {
            permissions[access.id][permission.contentTypeId] =
              permission.privilegeId;
          });
        }
      });
      setCustomPermissions(permissions); // Set the customPermissions state based on accessList
    }
  }, [accessList]);

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent("");
    setSelectedUsers([]);
  };

  const handleAdd = () => {
    const updatedAccessList = [...accessList];
    selectedUsers.forEach((user: any) => {
      const existingUser = updatedAccessList.find(
        (item) => item.userGroupId === user.id
      );
      if (!existingUser) {
        updatedAccessList.push({
          id: user.id,
          name: user.name,
          role: "viewer",
          isGroup: user.group,
          resources: Array(5).fill({ view: false, edit: false, create: false }),
          isBuffered: true,
        });
        setAddedUserIds((prevState: any) => [...prevState, user.id]);
      }
    });
    mutateAccessList(updatedAccessList, false);
    closeModal();
  };
  const handleUserSelection = (userId: any, isGroup: any, userName: any) => {
    setSelectedUsers([{ id: userId, group: isGroup, name: userName }]);
  };

   const showAlert = (message: string, severity: AlertColor) => {
      setTestMessage(message);
      setSeverity(severity);
      setOpenAlert(true);
      setTimeout(() => {
        setOpenAlert(false);
      }, 3000);
  };
  

  const handleRoleChange = (userGroupId: any, newRole: any) => {
    const updatedAccessList = accessList.map((item: any) =>
      item.id === userGroupId
        ? {
            ...item,
            role: newRole,
            isChange: true,
            customPermissions:
              newRole === "custom_creator"
                ? [
                    { contentTypeId: 2, privilegeId: 1 },
                    { contentTypeId: 3, privilegeId: 1 },
                    { contentTypeId: 4, privilegeId: 1 },
                    { contentTypeId: 5, privilegeId: 1 },
                  ]
                : item.customPermissions, // Set default permissions if role is custom_creator
          }
        : item
    );
    mutateAccessList(updatedAccessList, false);
    setEditedUserIds((prevState: any) => [...prevState, userGroupId]);
    setRevertedRows((prevState: any) => [...prevState, userGroupId]);
  };

  const handlePermissionChange = (
    userGroupId: any,
    contentTypeId: any,
    privilegeId: any
  ) => {
    // Update customPermissions
    setCustomPermissions((prev: any) => ({
      ...prev,
      [userGroupId]: {
        ...prev[userGroupId],
        [contentTypeId]:
          privilegeId !== 0 && privilegeId !== null
            ? Math.min(Math.max(privilegeId, 1), 3)
            : null, // Ensure privilegeId is between 1 and 3, or null
      },
    }));
    if (!editedUserIds.includes(userGroupId)) {
      setEditedUserIds([...editedUserIds, userGroupId]);
    }

    // Mark user as modified for the blue icon (if user role is "custom_creator")
    if (
      customPermissions[userGroupId]?.role === "custom_creator" &&
      !modifiedUsers.includes(userGroupId)
    ) {
      setModifiedUsers([...modifiedUsers, userGroupId]);
    }
    // Create a new accessList and update the respective user's customPermissions
    const updatedAccessList = accessList.map((access: any) => {
      if (access.id === userGroupId) {
        // Create or update the customPermissions for the current user
        const updatedPermissions = [
          {
            contentTypeId: 2,
            privilegeId: customPermissions[userGroupId]?.[2] || null,
          },
          {
            contentTypeId: 3,
            privilegeId: customPermissions[userGroupId]?.[3] || null,
          },
          {
            contentTypeId: 4,
            privilegeId: customPermissions[userGroupId]?.[4] || null,
          },
          {
            contentTypeId: 5,
            privilegeId: customPermissions[userGroupId]?.[5] || null,
          },
        ].filter((permission) => permission.privilegeId !== null);
        return { ...access, customPermissions: updatedPermissions };
      }
      return access;
    });
    mutateAccessList(updatedAccessList, false);
    console.log("Updated accessList:", updatedAccessList);
  };

  const handleOkClick = async (selectedUsers: any) => {
    // const roleMapping: any = {
    //     "owner": 3,
    //     "moderator": 4,
    //     "creator": 5,
    //     "viewer": 7,
    //     "custom_creator": 6,
    // };

    for (const selectedUser of selectedUsers) {
      console.log("accessList", selectedUser);
      if (
        selectedUser.isBuffered === true ||
        selectedUser.isChange === true ||
        Object.keys(customPermissions).length > 0
      ) {
        //const roleId: any = roleMapping[selectedUser.role || selectedUser.roleName];

        const roleId = roles.find(
          (item: any) =>
            item.value === (selectedUser.role || selectedUser.roleName)
        )?.id;

        let payload = [];

        // Set custom permissions if the role is custom_creator
        if (roleId === 6) {
          payload = [
            {
              granteeId: selectedUser.id,
              isGroup: selectedUser.isGroup,
              roleId: roleId,
              customPermissions: [
                {
                  contentTypeId: 2,
                  privilegeId: customPermissions[selectedUser.id]?.[2] || null,
                },
                {
                  contentTypeId: 3,
                  privilegeId: customPermissions[selectedUser.id]?.[3] || null,
                },
                {
                  contentTypeId: 4,
                  privilegeId: customPermissions[selectedUser.id]?.[4] || null,
                },
                {
                  contentTypeId: 5,
                  privilegeId: customPermissions[selectedUser.id]?.[5] || null,
                },
              ].filter((permission) => permission.privilegeId !== null),
            },
          ];
        } else {
          payload = [
            {
              granteeId: selectedUser.id,
              isGroup: selectedUser.isGroup,
              roleId: roleId,
            },
          ];
        }

        console.log(payload);
        try {
          let response;
          const existingUser = accessList.find(
            (item: any) => item.id === selectedUser.id
          );
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/json",
              "X-TENANT-ID": "silzila",
            },
          };

          if (existingUser) {
            response = await axios.post(
              `${serverEndPoint}workspace/user-access?workspaceId=${parentId}`,
              payload,
              config
            );
            mutateAccessList();
            setRevertedRows([]);
            setEditedUserIds([]);
            setSaveMessage(true);
            setModifiedUsers([]);
            setTimeout(() => {
              setSaveMessage(false);
            }, 1000);
          } else {
            console.log("Adding User");
            response = await axios.post(
              `${serverEndPoint}workspace/user-access?workspaceId=${parentId}`,
              payload,
              config
            );
            console.log("User added:", response.data);
            setAddedUserIds([]);
          }
          showAlert('The changes are saved', 'success');
          setAddedUserIds([]);
          mutateAccessList(); // Refresh the access list
        } catch (error) {
          console.error("Error granting access or updating role:", error);
        }
      }
    }
  };

  const handleDelete = async (
    userGroupId: any,
    isGroup: any,
    extraParam: any
  ) => {
    const token = localStorage.getItem("accessToken");
    const userAdded = addedUserIds.find((user: any) => user === userGroupId);
    if (userAdded) {
      //For deleting not saved but Added User
      let updatedAccessList = [...accessList];
      updatedAccessList = updatedAccessList.filter(
        (item) => item.id !== userGroupId
      );
      mutateAccessList(updatedAccessList, false);
      setAddedUserIds((prevState: any) =>
        prevState.filter((id: any) => id !== userGroupId)
      );
    } else {
      try {
        const userOrGroup = accessList.find(
          (item: any) => item.id === userGroupId
        ); // If roleId or roleName are not available in accessList, fetch them dynamically
        let roleId;

        if (userOrGroup && userOrGroup.role) {
          // const roleMapping: any = {
          //     "owner": 3,
          //     "moderator": 4,
          //     "creator": 5,
          //     "viewer": 7,
          //     "custom_creator": 6
          // };
          roleId = roles.find(
            (item: any) => item.value === userOrGroup.role
          )?.id;
        } else {
          // Default roleId if not available
          roleId = 7; // Assuming "viewer" as default
        }
        await axios.delete(
          `${serverEndPoint}workspace/access-deletion?workspaceId=${parentId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "X-TENANT-ID": "silzila",
            },
            data: [
              {
                granteeId: userGroupId,
                isGroup: isGroup,
                roleId: roleId,
              },
            ],
          }
        );
        let updatedAccessList = [...accessList];
        updatedAccessList = updatedAccessList.filter(
          (item) => item.id !== userGroupId
        );
        mutateAccessList(updatedAccessList, false);
        console.log("Access deleted");
      } catch (error) {
        console.error("Error deleting access:", error);
      }
    }
  };
  const removeBufferedUser = (id: any) => {
    const updatedAccessList = accessList.filter((item: any) => item.id !== id);
    mutateAccessList(updatedAccessList, false);
    mutateAccessList();
  };

  const handleRowReload = (userGroupId: any) => {
    const originalState = accessList.find(
      (item: any) => item.id === userGroupId
    );
    if (originalState.isBuffered) {
      removeBufferedUser(originalState.id);
      mutateAccessList();
    } else if (originalState) {
      const updatedAccessList = accessList.map((item: any) =>
        item.id === userGroupId ? originalState : item
      );
      setUsers(
        users.filter(
          (user: any) => !(user.id === userGroupId && user.isBuffered)
        )
      );
      setRevertedRows((prevState: any) =>
        prevState.filter((id: any) => id !== userGroupId)
      );
      setAddedUserIds((prevState: any) =>
        prevState.filter((id: any) => id !== userGroupId)
      );
      setEditedUserIds((prevState: any) =>
        prevState.filter((id: any) => id !== userGroupId)
      );
      mutateAccessList(updatedAccessList, false);
      mutateAccessList();
    }
    mutateAccessList(accessList, false);
    // console.log(`Row with ID ${userGroupId} reloaded`);
  };
  const reloadRow = (userGroupId: any) => {
    setRevertedRows((prevState: any) =>
      prevState.filter((id: any) => id !== userGroupId)
    );
    setAddedUserIds((prevState: any) =>
      prevState.filter((id: any) => id !== userGroupId)
    );
    setEditedUserIds((prevState: any) =>
      prevState.filter((id: any) => id !== userGroupId)
    );
  };
  const reloadButtonFunction = async () => {
    mutateAccessList();
    let accessListforReload;
    const updatedData = await FetchData({
      requestType: "noData",
      method: "GET",
      url: `workspace/access/${parentId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "X-TENANT-ID": "silzila",
      },
    });
    accessListforReload = JSON.parse(JSON.stringify(updatedData.data));
    const uniqueUserGroupIds = new Set(revertedRows);
    uniqueUserGroupIds.forEach((userGroupId) => reloadRow(userGroupId));
    setIsReverted(false);
    setAddedUserIds([]);
    setEditedUserIds([]);
    if (accessListforReload.length) {
      const permissions: any = {};
      accessListforReload.forEach((access: any) => {
        if (access.customPermissions && access.customPermissions.length > 0) {
          permissions[access.id] = {};
          access.customPermissions.forEach((permission: any) => {
            permissions[access.id][permission.contentTypeId] =
              permission.privilegeId;
          });
        }
      });
      setCustomPermissions(permissions);
    }
  };
  const Functions = () => {
    handleNewButtonClick();
    setModalContent("User");
  };
  const renderImage = (id: any) => {
    if (revertedRows.includes(id)) {
      return "/revert_active.png";
    } else if (!isReverted) {
      return "/revert_white.png";
    }
  };
  return (
    <>
    <div className="access-container" style={{ border: "1px solid transparent" }}>
      
      <button className={styles.backLink} onClick={handleBackButton}>
        Back
      </button>

      <div className="heading" style={{ height: "50px", border: "1px solid transparent" }}>
        <img
          src="/folder_work.png"
          style={{ width: "28px", height: "28px" }}
          alt="Folder Icon"
        ></img>
        <div>
          <h3>{parentWorkspaceName}</h3>
        </div>
      </div>
      
      
      <div className={styles.actionButtons}>
        <button className={styles.createWorkspace} onClick={Functions}>
          <img
            className="addIcon addIconDefault"
            src="/add_purple.png"
            alt="Add Users Logo"
            style={{ width: "20px", height: "20px" }}
          />
          &nbsp;New
        </button>
      </div>
      <div className={styles.resourcesTableContainer}>
        {accessList.length === 0 ? (
          <p className={styles.noAccessMessage}>
            Currently, no user/group have access to this particular workspace.
            Please add user/groups via New button.
          </p>
        ) : (
          <div>
            <table className={styles.resourcesTable}>
              <thead>
                <tr>
                  <th rowSpan={3}>Actions</th>
                  <th rowSpan={3}>State</th>
                  <th rowSpan={3}>User/Group</th>
                  <th rowSpan={3}>Roles</th>
                  <th colSpan={28}>Resources</th>
                </tr>
                <tr>
                  <th colSpan={4}>Sub Folder</th>
                  <th colSpan={4}>DB Connection</th>
                  <th colSpan={4}>Data Sets</th>
                  <th colSpan={4}>Data Files</th>
                  <th colSpan={4}>Playbook</th>
                </tr>
                <tr>
                  {Array(5)
                    .fill(null)
                    .map((_, index) => (
                      <React.Fragment key={index}>
                        <th>
                          <img
                            src="/none_header.png"
                            alt="None"
                            style={{ width: "17px", height: "17px" }}
                          ></img>
                        </th>
                        <th>
                          <img
                            src="/view_header.png"
                            alt="View"
                            style={{ width: "17px", height: "17px" }}
                          ></img>
                        </th>
                        <th>
                          <img
                            src="/edit_header.png"
                            alt="Edit"
                            style={{ width: "17px", height: "17px" }}
                          ></img>
                        </th>
                        <th>
                          <img
                            src="/create_header.png"
                            alt="Create"
                            style={{ width: "17px", height: "17px" }}
                          ></img>
                        </th>
                      </React.Fragment>
                    ))}
                </tr>
              </thead>
              <tbody>
                {accessList.map((access: any) => (
                  <tr key={access.id}>
                    <td className={styles.actButton}>
                      {/* Row reload disabled for now */}
                      {/* <button
                        className={styles.iconButton}
                        onClick={() => handleRowReload(access.id)}
                      >
                        <Tooltip title="Reload">
                          <img
                            src={renderImage(access.id)}
                            alt="Reload Row"
                            style={{ width: "17px", height: "17px" }}
                          />
                        </Tooltip>
                      </button> */}
                      <button
                        className={styles.iconButton}
                        onClick={() =>
                          handleDelete(
                            access.id,
                            access.isGroup,
                            access.roleName
                          )
                        }
                      >
                        <Tooltip title="Delete">
                          <img
                            src="/delete_red.png"
                            alt="Delete User"
                            style={{ width: "17px", height: "17px" }}
                          ></img>
                        </Tooltip>
                      </button>
                    </td>
                    {/* <td className={styles.stateButton}>
                                            <Tooltip title="Add User">
                                                <img
                                                    src={addedUserIds.includes(access.id) ? "/add_access_active.png" : "/add_access.png"}
                                                    alt="Add Users Logo"
                                                    style={{ width: "17px", height: "17px"}}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Edit User">
                                                <img
                                                    src={editedUserIds.includes(access.id) ? "/edit_access.png" : "/edit_access_white.png"}
                                                    alt="edit Users Logo"
                                                    style={{ width: "17px", height: "17px"}}
                                                />
                                            </Tooltip>
                                        </td> */}
                    <td className={styles.stateButton}>
                      {/* Render nothing if the user has no added/edited state */}
                      {!addedUserIds.includes(access.id) &&
                        !editedUserIds.includes(access.id) &&
                        !modifiedUsers.includes(access.id) && <span></span>}

                      {/* Show 'add' icon if the user was added */}
                      {addedUserIds.includes(access.id) && (
                        // !editedUserIds.includes(access.id) &&
                        <Tooltip title="Add User">
                          <img
                            src="/add_access_active.png"
                            alt="Add Users Logo"
                            width={17}
                            height={17}
                          />
                        </Tooltip>
                      )}

                      {/* Show 'edit' icon if the user was edited */}
                      {editedUserIds.includes(access.id) &&
                        !addedUserIds.includes(access.id) && (
                          <Tooltip title="Edit User">
                            <img
                              src="/edit_access.png"
                              alt="Edit Users Logo"
                              width={17}
                              height={17}
                            />
                          </Tooltip>
                        )}
                      {modifiedUsers.includes(access.id) && (
                        <Tooltip title="Edit User">
                          <img
                            src="/edit_access.png" // Path to the blue icon
                            alt="Modified Icon"
                            width={17}
                            height={17}
                          />
                        </Tooltip>
                      )}
                    </td>

                    <td>{access.name}</td>
                    <td>
                      <select
                        className={styles.roleDropdown}
                        onChange={(e) =>
                          handleRoleChange(access.id, e.target.value)
                        }
                        value={access.role}
                      >
                        {roles.map((role, index) => (
                          <option key={index} value={role.value}>
                            {role.displayValue}
                          </option>
                        ))}
                      </select>
                    </td>
                    {Array(5)
                      .fill(null)
                      .map((_, index) => {
                        const privilegeId =
                          customPermissions[access.id]?.[index + 1] || 0; // Default to 0 if no permission is set

                        return (
                          <React.Fragment key={access.id}>
                            <td colSpan={4}>
                              <StepSlider
                                role={access.role}
                                column={index + 1} // Start column IDs from 1
                                initialValue={privilegeId} // Pass initial privilegeId to StepSlider
                                onPermissionChange={(
                                  column: any,
                                  privilegeId: any
                                ) =>
                                  handlePermissionChange(
                                    access.id,
                                    column,
                                    privilegeId
                                  )
                                }
                              />
                            </td>
                          </React.Fragment>
                        );
                      })}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={styles.tableButtons}>
              <div>
                <button
                  className={styles.reloadButton}
                  onClick={() => reloadButtonFunction()}
                >
                  Reload
                </button>
                <button
                  className={styles.okButton}
                  onClick={() => handleOkClick(accessList)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <Dialog open={isModalOpen} onClose={closeModal}>
        <div className={styles.accessModal}>
          <div className={styles.accessModalContent}>
            <div className={styles.accessHeaderSection}>
              <Tooltip title="Close">
                <CloseIcon
                  onClick={closeModal}
                  sx={{
                    fontSize: "large",
                    color: "#545454",
                    cursor: "pointer",
                    transform: "translate(590px, 10px)",
                    "&:hover": {
                      color: "red",
                    },
                  }}
                />
              </Tooltip>

              <h3 className={styles.accessModalTitle}>Select {modalContent}</h3>

              <div className={styles.accessModalButtons}>
                <button
                  onClick={() => setModalContent("User")}
                  className={styles.accessModalButton1}
                  style={
                    modalContent === "User"
                      ? { backgroundColor: "#239b9c", color: "white" }
                      : {}
                  }
                >
                  Users
                </button>
                <button
                  onClick={() => setModalContent("Groups")}
                  className={styles.accessModalButton1}
                  style={
                    modalContent === "Groups"
                      ? { backgroundColor: "#239b9c", color: "white" }
                      : {}
                  }
                >
                  Groups
                </button>
              </div>
            </div>

            <div className={styles.accessTableContainer}>
              {modalContent === "User" && (
                <table className={styles.accessUserList}>
                  <thead>
                    <tr className={styles.accessModalHeader}>
                      <th></th>
                      <th></th>
                      <th>Name</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalData
                      .filter(
                        (user: any) =>
                          !user.isGroup && !addedUserIds.includes(user.id)
                      )
                      .map((user: any) => (
                        <tr key={user.id} className={styles.accessUserItem}>
                          <td>
                            <input
                              type="radio"
                              checked={selectedUsers.some(
                                (selectedUser: any) =>
                                  selectedUser.id === user.id
                              )}
                              onChange={() =>
                                handleUserSelection(
                                  user.id,
                                  user.isGroup,
                                  user.name
                                )
                              }
                            />
                          </td>
                          <td>
                            <img
                              src={
                                user.profileImage
                                  ? `data:image/jpeg;base64,${user.profileImage}`
                                  : "/default.png"
                              }
                              alt="Users Icon"
                              height={17}
                              width={17}
                            />
                          </td>
                          <td>{user.name}</td>
                          <td>{user.emailId}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

              {modalContent === "Groups" && (
                <table className={styles.accessUserList}>
                  <thead>
                    <tr className={styles.accessModalHeader}>
                      <th></th>
                      <th></th>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalData
                      .filter(
                        (group: any) =>
                          group.isGroup && !addedUserIds.includes(group.id)
                      )
                      .map((group: any) => (
                        <tr key={group.id} className={styles.accessUserItem}>
                          <td>
                            <input
                              type="radio"
                              checked={selectedUsers.some(
                                (selectedUser: any) =>
                                  selectedUser.id === group.id
                              )}
                              onChange={() =>
                                handleUserSelection(
                                  group.id,
                                  group.isGroup,
                                  group.name
                                )
                              }
                            />
                          </td>
                          <td>
                            <img
                              src="/groups.png"
                              alt="Group Icon"
                              style={{ width: "17px", height: "17px" }}
                            />
                          </td>
                          <td>{group.name}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>

            <div className={styles.accessFooterSection}>
              <div className={styles.accessModalFooterButtons}>
                <button
                  onClick={closeModal}
                  className={styles.accessModalFooterButton2}
                >
                  Cancel
                </button>
                <button
                  onClick={handleAdd}
                  className={styles.accessModalFooterButton1}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <NotificationDialog
             openAlert={openAlert}
             severity={severity}
             testMessage={testMessage}
             onCloseAlert={() => {
               setOpenAlert(false);
               setTestMessage("");
             }}
      />
      </div>
    </>
  );
};
export default Access;
