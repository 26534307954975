import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import Header from "../Components/Header";
import AccessforContents from "../Components/AccessforContents";
import "./pages.css";

const AccessPageforContents = () => {
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const storedState =
      typeof window !== "undefined"
        ? localStorage.getItem("navbarCollapsed")
        : null;
    return storedState ? JSON.parse(storedState) : false;
  });

  const toggleNavbar = () => {
    const newState = !isCollapsed;
    setIsCollapsed(newState);
    if (typeof window !== "undefined") {
      localStorage.setItem("navbarCollapsed", JSON.stringify(newState));
    }
  };

  useEffect(() => {
    const storedState = localStorage.getItem("navbarCollapsed");
    if (storedState) {
      setIsCollapsed(JSON.parse(storedState));
    }
  }, []);

  return (
    <>
      <Header />
      <div className="layout-container">
        <div className={`navbar-container ${isCollapsed ? "collapsed" : ""}`}>
          <Navbar isCollapsed={isCollapsed} toggleNavbar={toggleNavbar} />
        </div>
        <div className="component-container">
          <AccessforContents />
        </div>
      </div>
    </>
  );
};
export default AccessPageforContents;
