import React from 'react'
import { useDrag } from "react-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Tooltip } from '@mui/material';
const DraggableCalculationListItem = ({ name, type, fieldData, colsOnly, definition, string,flowName }: any) => {
    const [collect, drag] = useDrag({
        type: "calculationSelected",
        item: { flowName,name, type, fieldData, bIndex: 99 },
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
            isDragging: monitor.isDragging() ? true : false,
        }),

    });

    return (
        <div ref={drag} className={'styleForColumnsOnly'}>
            <DragIndicatorIcon style={{ marginLeft: "-2px" }} fontSize="small" />
            {
                !collect.isDragging && <Tooltip title={definition} placement="right">
                    <span style={{
                        color: '#333',
                        fontSize:'14px',
                        textTransform: "capitalize",
                        fontWeight:'400',
                    }} className="boxText">{
                            name === 'IfElse' ? 'If Else' : String(name[0]).toUpperCase() + name.slice(1)
                        }</span>
                </Tooltip>
            }
            {
                collect.isDragging &&
                <span style={{
                    color: '#333',
                    textTransform: "capitalize",
                    fontFamily:'Axiforma Black',
                    fontSize:'14px',
                    fontWeight:'400',
                }} className="boxText">{
                        name === 'IfElse' ? 'If Else' : String(name[0]).toUpperCase() + name.slice(1)
                    }</span>
            }

        </div>
    )
}

export default DraggableCalculationListItem
