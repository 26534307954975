import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { createRoot } from 'react-dom/client';
import './index.css';
import './assets/Fonts/fonts.css'
import { StyledEngineProvider ,ThemeProvider, createTheme} from "@mui/material/styles";
import { CssBaseline } from '@mui/material';
const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto-Regular',
      'Roboto-Medium',
      'Roboto-Bold',
      'Roboto-Light',
      'Segoe UI',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'Roboto-Regular,Roboto-Medium,Roboto-Bold,Roboto-Light, Segoe UI, Helvetica, Arial, sans-serif',
        },
      },
    },
  },
});
const container = document.getElementById('root');

const root = createRoot(container  as HTMLElement); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline  />
      <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </BrowserRouter>
);

