"use client";
import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import useSWR from "swr";
import styles from "./adminlist.module.css";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Tooltip, AlertColor } from "@mui/material";
import { NotificationDialog } from "./CommonFunctions/DialogComponents";
import { useNavigate } from "react-router-dom";
import {serverEndPoint} from "./ServerCall/EnvironmentVariables";


// Define a fetcher function
const fetcher = (url: string) =>
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);

const axiosInstance = axios.create({
  headers: {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    "X-TENANT-ID": "silzila",
    "Content-type": "application/json",
  },
});

const AdminList = () => {
  const navigate = useNavigate();
  const [showAddAdminDialog, setShowAddAdminDialog] = useState(false);
  const [modalSelectedUsers, setModalSelectedUsers] = useState<string | null>(
    null
  ); // Store single ID
  const [activeTab, setActiveTab] = useState("users");
  const [modalSelectedGroups, setModalSelectedGroups] = useState<string | null>(
    null
  ); // Store single ID
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [userIndexToRemove, setUserIndexToRemove] = useState<string | null>(
    null
  );
  const [adminToRemove, setAdminToRemove] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [groupSearchQuery, setGroupSearchQuery] = useState("");
  const [showTransferAdminDialog, setShowTransferAdminDialog] = useState(false);
  const [selectedTransferUserId, setSelectedTransferUserId] = useState<
    string | null
  >(null);
  const [showConfirmTransferDialog, setShowConfirmTransferDialog] =
    useState(false);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [testMessage, setTestMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  }>({ key: "name", direction: "asc" });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Use SWR for admins
  const {
    data: admins = [],
    error: adminsError,
    isValidating: isAdminsLoading,
    mutate: mutateAdmins,
  } = useSWR(`${serverEndPoint}admin/list`, fetcher, {
    revalidateOnFocus: false,
  });

  const filteredAdmins: any = admins
    .filter((admin: any) =>
      admin.name.toLowerCase().startsWith(searchQuery.toLowerCase())
    )

    .sort((a: any, b: any) => {
      if (a.roleName === "account_admin") return -1;
      if (b.roleName === "account_admin") return 1;
      let aValue, bValue;

      if (sortConfig.key === "name") {
        aValue = a.name.toLowerCase();
        bValue = b.name.toLowerCase();
      } else if (sortConfig.key === "email") {
        aValue = a.emailId;
        bValue = b.emailId;
      }

      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });

  // Extract admin user and group IDs
  const adminUserIds = useMemo(
    () =>
      admins
        .filter((admin: any) => !admin.isGroup)
        .map((admin: any) => admin.id),
    [admins]
  );
  const adminGroupIds = useMemo(
    () =>
      admins
        .filter((admin: any) => admin.isGroup)
        .map((admin: any) => admin.id),
    [admins]
  );

  // Use SWR for users
  const {
    data: allUsers = [],
    error: usersError,
    isValidating: isUsersLoading,
  } = useSWR(
    activeTab === "users" ? `${serverEndPoint}users/list` : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  // Use SWR for groups
  const {
    data: allGroups = [],
    error: groupsError,
    isValidating: isGroupsLoading,
  } = useSWR(
    activeTab === "groups" ? `${serverEndPoint}allgroups` : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  // Optional: State to store users with profile images
  const [usersWithImages, setUsersWithImages] = useState<any[]>([]);

  // Filter only users from admins (exclude groups)
  const adminUsers = useMemo(
    () => admins.filter((admin: any) => !admin.isGroup),
    [admins]
  );

  // Function to open transfer admin dialog
  const openTransferAdminDialog = () => {
    setShowTransferAdminDialog(true);
    setSelectedTransferUserId(null); // Reset selection
  };

  const showAlert = (message: string, severity: AlertColor) => {
    setTestMessage(message);
    setSeverity(severity);
    setOpenAlert(true);
    setTimeout(() => {
      setOpenAlert(false);
    }, 3000);
  };

  // Function to handle transfer confirmation
  const handleTransferAdminConfirm = async () => {
    if (!selectedTransferUserId) {
      showAlert("No user selected to transfer.", "info");
      return;
    }

    try {
      const response = await axios.post(
        `${serverEndPoint}account-admin/transistion`,
        {
          granteeId: selectedTransferUserId,
          isGroup: false,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Transfer Admin API Response:", response);
      if (response.status === 200) {
        showAlert(
          "Super Admin rights transferred successfully. You will be logged out. Please login again.",
          "success"
        );
        setShowTransferAdminDialog(false);
        setShowConfirmTransferDialog(false);
        mutateAdmins(); // Refresh admin list after transfer
        // Navigate to login page after transfer
        navigate("/Login");
      } else {
        showAlert(
          response.data.message || "Failed to transfer admin.",
          "error"
        );
        console.error("Failed to transfer admin:", response.data);
      }
    } catch (error: any) {
      if (error.response) {
        const errorMessage =
          error.response.data.message ||
          "An error occurred while transferring admin.";
        showAlert(errorMessage, "error");
      } else if (error.request) {
        showAlert("No response from server. Please try again later.", "error");
      } else {
        showAlert(error.message || "Unexpected error occurred.", "error");
      }
    } finally {
      setOpenAlert(true);
      setShowTransferAdminDialog(false);
      setShowConfirmTransferDialog(false);
    }
  };

  const handleSort = (key: any) => {
    const newDirection =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction: newDirection });
  };

  const getSortIcon = (key: any) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <img className="headerIcon-up" src="/sort-up.png" alt="Ascending" />
      ) : (
        <img
          className="headerIcon-down"
          src="/sort-down.png"
          alt="Descending"
        />
      );
    }
    return <img className="headerIcon" src="/sort.png" alt="Default" />;
  };

  // useEffect(() => {
  //   const fetchProfileImages = async () => {
  //     if (allUsers.length > 0) {
  //       const updatedUsers = await Promise.all(
  //         allUsers.map(async (user: any) => {
  //           try {
  //             const imageResponse = await axiosInstance.get(
  //               `${serverEndPoint}user/profile-image`,
  //               {
  //                 params: { userId: user.id },
  //               }
  //             );

  //             let profileImageUrl;
  //             if (imageResponse.data && user.profileImage) {
  //               profileImageUrl = `data:image/jpeg;base64,${user.profileImage}`;
  //             } else {
  //               profileImageUrl = "/default.png";
  //             }
  //             return { ...user, profileImage: profileImageUrl };
  //           } catch (error) {
  //             console.error(
  //               `Failed to fetch image for user ${user.id}:`,
  //               error
  //             );
  //             return { ...user, profileImage: "/default.png" };
  //           }
  //         })
  //       );
  //       setUsersWithImages(updatedUsers);
  //     }
  //   };

  //   fetchProfileImages();
  // }, [allUsers]);

  useEffect(() => {
    let isMounted = true;
    
  
    const fetchProfileImages = async () => {
      if (!allUsers || allUsers.length === 0) return;
  
      try {
        // Filter users who already have a profileImage
        const usersToFetch = allUsers.filter(
          (user: { profileImage: any; isAdmin: boolean }) => user.profileImage && user.isAdmin
        );
        console.log(usersToFetch)
        
        if (usersToFetch.length === 0) {
          setUsersWithImages(allUsers);
          return;
        }
  
        const updatedUsers = await Promise.all(
          usersToFetch.map(async (user:any) => {
            try {
              //console.log(user)
              const imageResponse = await axiosInstance.get(
                `${serverEndPoint}user/profile-image`,
                { params: { userId: user.id } }
              );
  
              const profileImageUrl =
                imageResponse.data?.image
                  ? `data:image/jpeg;base64,${imageResponse.data.image}`
                  : "/default.png";
  
              return { ...user, profileImage: profileImageUrl };
            } catch (error) {
              return { ...user, profileImage: "/default.png" };
            }
          })
        );
  
        if (isMounted) {
          // Merge the updated users back with the rest of the users
          const finalUsers = allUsers.map((user:any) =>
            updatedUsers.find((u) => u.id === user.id) || user
          );
  
          setUsersWithImages(finalUsers);
        }
      } catch (error) {
        console.error("Failed to fetch profile images:", error);
      }
    };
  
    fetchProfileImages();
  
    return () => {
      isMounted = false;
    };
  }, [allUsers]);
  

  const filteredUsers = useMemo(() => {
    if (!usersWithImages) return []; // Return an empty array if no users
    return usersWithImages.filter(
      (user: any) =>
        !adminUserIds.includes(user.id) && // Exclude already admins
        !user.isDeleted && // Exclude Deleted Users
        (user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.email.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  }, [usersWithImages, searchQuery, adminUserIds]);

  const filteredGroups = useMemo(() => {
    if (!allGroups || !allUsers) return []; // Return an empty array if no groups
    return allGroups.filter(
      (group: any) =>
        !adminGroupIds.includes(group.id) && // Exclude already admin groups
        group.groupName
          .toLowerCase()
          .includes(groupSearchQuery.toLowerCase()) &&
        group.userCount !== allUsers.length
    );
  }, [allGroups, groupSearchQuery, adminGroupIds, !allUsers]);

  const toggleModalSelectUser = (userId: string) => {
    setModalSelectedUsers((prevSelected) =>
      prevSelected === userId ? null : userId
    );
  };

  const toggleModalSelectGroup = (groupId: string) => {
    setModalSelectedGroups((prevSelected) =>
      prevSelected === groupId ? null : groupId
    );
  };

  const toggleUserSelection = (userId: string) => {
    setSelectedUserIds((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };

  const confirmRemoveUser = async () => {
    try {
      const adminToRemove: any = admins.find(
        (admin: any) => admin.id === userIndexToRemove
      );
      if (!adminToRemove) {
        console.error("Admin not found for deletion.");
        showAlert("Admin not found for deletion.", "warning");
        return;
      }

      const response = await axios.delete(
        `${serverEndPoint}admin/delete`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
          data: {
            granteeId: adminToRemove.id,
            isGroup: adminToRemove.isGroup || false, // Ensure correct type
          },
        }
      );
      mutateAdmins();
      if (response.status === 200) {
        showAlert(
          response.data.message || "Admin removed successfully.",
          "success"
        );
        mutateAdmins(); // Refresh admin list after removal
      } else {
        showAlert(response.data.message || "Failed to remove admin.", "error");
      }
    } catch (error: any) {
      if (error.response) {
        showAlert(
          error.response.data.message || "Error removing admin.",
          "error"
        );
        console.error("Axios Error - Response:", error.response);
      } else if (error.request) {
        showAlert(
          "No response received from server. Please try again later.",
          "error"
        );
        console.error("Axios Error - No Response Received:", error.request);
      } else {
        showAlert(error.message || "Unexpected error occurred.", "error");
        console.error("Axios Error - Other:", error.message);
      }
    } finally {
      setOpenAlert(true);
      setShowRemoveConfirmation(false);
      setUserIndexToRemove(null);
    }
  };

  const removeUser = (adminId: string) => {
    setUserIndexToRemove(adminId);
    const admin = admins.find((admin: any) => admin.id === adminId);
    setAdminToRemove(admin);
    setShowRemoveConfirmation(true);
  };

  const cancelRemove = () => {
    setShowRemoveConfirmation(false);
    setUserIndexToRemove(null);
  };

  const handleAddAdminConfirm = async () => {
    try {
      const isGroup = activeTab === "groups";
      const selectedId = isGroup ? modalSelectedGroups : modalSelectedUsers;

      if (!selectedId) {
        const infoMessage = isGroup
          ? "No group selected to add as admin."
          : "No user selected to add as admin.";
        showAlert(infoMessage, "info");
        return;
      }

      console.log("Selected ID:", selectedId, "Is Group:", isGroup);

      const response = await axios.post(
        `${serverEndPoint}user/admin`,
        {
          granteeId: selectedId,
          isGroup: isGroup,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response Status:", response.status);
      console.log("Response Data:", response.data);

      if (response.status === 201) {
        setShowAddAdminDialog(false);
        setModalSelectedUsers(null);
        setModalSelectedGroups(null);
        setSearchQuery("");
        setGroupSearchQuery("");
        mutateAdmins();
        showAlert("Admin added Successfully!", "success");
      } else {
        console.error("Failed to add admin. Response data:", response.data);
        setModalSelectedUsers(null);
        setModalSelectedGroups(null);
        mutateAdmins();
        showAlert(response.data.message || "Failed to add admin", "error");
      }
    } catch (error: any) {
      if (error.response) {
        console.error("Axios Error - Response:", error.response);
      } else if (error.request) {
        console.error("Axios Error - No Response Received:", error.request);
      } else {
        console.error("Axios Error - Other:", error.message);
      }
    }
  };

  const resetModalState = () => {
    setModalSelectedUsers(null);
    setModalSelectedGroups(null);
    setSearchQuery("");
    setGroupSearchQuery("");
  };

  if (adminsError)
    return (
      <div className="loading-error-info">
        <p className="loading-default-text">Error loading admins.</p>
      </div>
    );
  if (usersError)
    return (
      <div className="loading-error-info">
        <p className="loading-default-text">Error loading users.</p>
      </div>
    );
  if (groupsError)
    return (
      <div className="loading-error-info">
        <p className="loading-default-text">Error loading groups.</p>
      </div>
    );

  return (
    <div className={`${styles.adminContainer} admin-container`}>
      <div
        className="heading"
        style={{
          height: "50px",
          display: "flex",
          marginLeft: "15px",
          paddingTop: "5px",
          border: "1px solid transparent",
        }}
      >
        <img
          src="/admin.png"
          alt="Admins"
          style={{ width: "22px", height: "22px" }}
        />
        <div>
          <h3
            style={{
              color: "#616164",
              marginTop: "-4px",
              marginBottom: "5px",
              marginLeft: "6px",
            }}
          >
            Admins
          </h3>
        </div>
      </div>

      {/*  <div className={styles.adminBackLinkContainer}>          
            <button className={styles.backLinkAdmin}>
            <Link to='/'><button className='back-link-user'>Back</button></Link>            
            </button>
          </div>  */}

      <div className="adminButtonAddSearchContainer">
        <div className="adminActionButtons">
          <button
            className={`${styles.adminAddButton} admin-addButton`}
            onClick={() => setShowAddAdminDialog(true)}
          >
            <img
              className={`${styles.adminAddIconDefault} addIconDefault`}
              src="/add_purple.png"
              alt="Add Admin Logo"
              style={{ width: "16px", height: "16px" }}
            />
            <img
              className={`${styles.adminAddIconHover} addIconHover`}
              src="/add_white.png"
              alt="Add Admin Logo Hover"
              style={{ width: "16px", height: "16px" }}
            />
            Add Admin
          </button>
        </div>

        <div className="adminSelectActionContainer">
          <div className="adminActionsContainer"></div>

          <div className="adminSearchContainer">
            <input
              type="text"
              placeholder="Search Admins"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="adminSearchInput"
            />
            <img
              src="/glass.png"
              alt="Search Icon"
              className="adminSearchIcon"
            />
          </div>
        </div>
      </div>

      <>
        {isAdminsLoading || isUsersLoading || isGroupsLoading ? (
          <div className="loading-container">
            <div className="spinner"></div>
          </div>
        ) : admins.length === 0 && searchQuery.length === 0 ? (
          <div className="no-user-info">
            <p className="loading-default-text">
              Currently, there are no Admins available.
            </p>
          </div>
        ) : (
          <div className="adminTableContainer">
            <table className={`${styles.adminTable} admin-table`}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th onClick={() => handleSort("name")}>
                    Name{" "}
                    <span className="icon-wrapper">{getSortIcon("name")}</span>
                  </th>
                  <th onClick={() => handleSort("email")}>
                    Email{" "}
                    <span className="icon-wrapper">{getSortIcon("email")}</span>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredAdmins.map((admin: any) => (
                  <tr
                    key={admin.id}
                    onMouseEnter={() => setHoveredRow(admin.id)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    <td>
                      {/* <input
                        type="checkbox"
                        checked={selectedUserIds.includes(admin.id)}
                        onChange={() => toggleUserSelection(admin.id)}
                        style={{ width: "16px", height: "16px" }}
                      /> */}
                    </td>

                    <td>
                      <img
                        src={
                          admin.isGroup
                            ? "/groups.png"
                            : admin.profileImage
                            ? `data:image/jpeg;base64,${admin.profileImage}`
                            : "/default.png"
                        }
                        style={{ width: "20px", height: "20px", borderRadius: "50%" }}
                        alt={admin.isGroup ? "Group Icon" : "User Profile"}
                      />
                    </td>
                    <td>
                      {admin.roleName === "account_admin" ? (
                        <Tooltip title="Super Admin">
                          <img
                            src="/crown-admin.png"
                            alt="Admin Icon"
                            style={{
                              transform: "translateY(3px)",
                              width: "15px",
                              height: "15px",
                              marginRight: "15px",
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <span
                          style={{
                            display: "inline-block",
                            width: "15px",
                            height: "15px",
                            marginRight: "15px",
                          }}
                        ></span>
                      )}
                      {admin.isGroup ? (
                        <Link
                          to={`/group-details/${admin.id}`}
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                          onMouseEnter={(e) => {
                            const target = e.target as HTMLElement;
                            target.style.textDecoration = "underline";
                            target.style.color = "#2bb9bb";
                          }}
                          onMouseLeave={(e) => {
                            const target = e.target as HTMLElement;
                            target.style.textDecoration = "none";
                            target.style.color = "#616164";
                          }}
                        >
                          {admin.name}
                        </Link>
                      ) : (
                        admin.name
                      )}
                    </td>

                    <td>{admin.isGroup ? "-" : admin.emailId}</td>
                    <td>
                      {admin.roleName === "account_admin" ? (
                        <button
                          onClick={openTransferAdminDialog}
                          style={{
                            background: "none",
                            border: "none",
                            padding: 0,
                            cursor: "pointer",
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                        >
                          <Tooltip title="Transfer Super Admin">
                            <img
                              src={
                                hoveredRow === admin.id
                                  ? "/transfer.png"
                                  : "/transfer_grey.png"
                              }
                              alt="Transfer Admin"
                              style={{ width: "17px", height: "17px" }}
                            />
                          </Tooltip>
                        </button>
                      ) : (
                        <button
                          onClick={() => removeUser(admin.id)}
                          style={{
                            background: "none",
                            border: "none",
                            padding: 0,
                            cursor: "pointer",
                            marginLeft: "5px",
                            marginRight: "5px",
                          }}
                        >
                          <Tooltip title="Remove Admin">
                            <img
                              src={
                                hoveredRow === admin.id
                                  ? "/cross_red.png"
                                  : "/cross.png"
                              }
                              alt="Remove Admin"
                              style={{ width: "17px", height: "17px" }}
                            />
                          </Tooltip>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>

      <Dialog
        open={showAddAdminDialog}
        onClose={() => {
          setShowAddAdminDialog(false);
          resetModalState();
        }}
        maxWidth="md"
      >
        <div className="adminListModal">
          <div className="adminListModalContent">
            <div className="adminListHeaderSection">
              <div className="adminListHeaderClose">
                <Tooltip title="Close">
                  <CloseIcon
                    onClick={() => {
                      setShowAddAdminDialog(false);
                      resetModalState();
                    }}
                    sx={{
                      fontSize: "large",
                      color: "#545454",
                      cursor: "pointer",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  />
                </Tooltip>
              </div>

              <h3 className="admin-modal-title">
                {activeTab === "users"
                  ? "Select User to Add as Admin"
                  : "Select Group to Add as Admin"}
              </h3>
              <div className={styles.adminListModalTabs}>
                {/* Tab Switch */}
                <div className={styles.tabContainer}>
                  <div
                    onClick={() => setActiveTab("users")}
                    className={`${styles.tab} ${
                      activeTab === "users" ? styles.activeTab : ""
                    }`}
                  >
                    Users
                  </div>
                  <div
                    onClick={() => setActiveTab("groups")}
                    className={`${styles.tab} ${
                      activeTab === "groups" ? styles.activeTab : ""
                    }`}
                  >
                    Groups
                  </div>
                </div>
              </div>

              {activeTab === "users" && (
                <div className="adminListSearchContainer">
                  {/* User Search */}
                  <input
                    type="text"
                    placeholder="Search users"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="adminListSearchInput"
                  />
                  <img
                    src="/glass.png"
                    alt="Search Icon"
                    className="adminListSearchIcon"
                  />
                </div>
              )}
              {activeTab === "groups" && (
                <div className="adminListSearchContainer">
                  {/* Group Search */}
                  <input
                    type="text"
                    placeholder="Search groups"
                    value={groupSearchQuery}
                    onChange={(e) => setGroupSearchQuery(e.target.value)}
                    className="adminListSearchInput"
                  />
                  <img
                    src="/glass.png"
                    alt="Search Icon"
                    className="adminListSearchIcon"
                  />
                </div>
              )}
            </div>

            <div className="adminListTableContainer">
              {activeTab === "users" && (
                <table
                  className={`${styles.adminListUserList} adminListUserList`}
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>Name</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isUsersLoading ? (
                      <>
                        <p>Loading users...</p>
                        <div className="spinner"></div>
                      </>
                    ) : filteredUsers.length > 0 ? (
                      filteredUsers.map((user: any) => (
                        <tr key={user.id}>
                          <td>
                            <input
                              type="radio"
                              checked={modalSelectedUsers === user.id}
                              onChange={() => toggleModalSelectUser(user.id)}
                            />
                          </td>
                          <td>
                            <img
                              src={user.profileImage || "/default.png"}
                              style={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                              }}
                              alt="User Profile"
                            />
                          </td>
                          <td>
                            {user.firstName} {user.lastName}
                          </td>
                          <td>{user.email}</td>
                        </tr>
                      ))
                    ) : (
                      <p>No User found</p>
                    )}
                  </tbody>
                </table>
              )}

              {activeTab === "groups" && (
                <table
                  className={`${styles.adminListGroupList} adminListGroupList`}
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Group Name</th>
                      <th>User Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredGroups.length > 0 ? (
                      filteredGroups.map((group: any) => (
                        <tr key={group.id}>
                          <td>
                            <input
                              type="radio"
                              checked={modalSelectedGroups === group.id}
                              onChange={() => toggleModalSelectGroup(group.id)}
                            />
                          </td>
                          <td>{group.groupName}</td>
                          <td>{group.userCount}</td>
                        </tr>
                      ))
                    ) : (
                      <p>No group found</p>
                    )}
                  </tbody>
                </table>
              )}
            </div>

            <div className="adminListFooterSection">
              <button
                onClick={handleAddAdminConfirm}
                className="adminListModalFooterButton2"
              >
                Add Admin
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog open={showRemoveConfirmation} onClose={cancelRemove}>
        <div className="admin-remove-modal">
          <div className="admin-remove-modalContent-close">
            <Tooltip title="Close">
              <CloseIcon
                onClick={() => setShowRemoveConfirmation(false)}
                sx={{
                  fontSize: "large",
                  color: "#545454",
                  cursor: "pointer",
                  "&:hover": {
                    color: "red",
                  },
                }}
              />
            </Tooltip>
          </div>
          <div className="admin-remove-modalContent-p">
            <p>
              Are you sure you want to remove {adminToRemove?.name} as admin?
            </p>
          </div>

          <div className="admin-remove-modalContent-Buttons">
            <button onClick={cancelRemove} className="admin-remove-modalCancel">
              Cancel
            </button>
            <button
              onClick={confirmRemoveUser}
              className="admin-remove-modalConfirm"
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog open={showTransferAdminDialog}>
        <div className="adminListModal">
          <div className="adminListModalContent">
            <div className="adminListHeaderSection">
              <div className="adminListHeaderClose">
                <Tooltip title="Close">
                  <CloseIcon
                    onClick={() => setShowTransferAdminDialog(false)}
                    sx={{
                      fontSize: "large",
                      color: "#545454",
                      cursor: "pointer",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  />
                </Tooltip>
              </div>

              <h3 className="admin-modal-title">
                Transfer Super Admin Account
              </h3>
              <p>Select a user to grant Super Admin privileges:</p>
            </div>

            <div className="adminListTableContainer">
              <table
                className={`${styles.adminListUserListTransfer} adminListUserListTransfer`}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {adminUsers
                    .filter(
                      (adminUser: any) => adminUser.roleName !== "account_admin"
                    ) // Exclude account_admin users
                    .map((adminUser: any) => (
                      <tr key={adminUser.id}>
                        <td>
                          <input
                            type="radio"
                            name="transferAdmin"
                            checked={selectedTransferUserId === adminUser.id}
                            onChange={() =>
                              setSelectedTransferUserId(adminUser.id)
                            }
                          />
                        </td>

                        <td>{adminUser.name}</td>
                        <td>{adminUser.emailId}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div className="adminListFooterSection" style={{ bottom: "15px" }}>
              <button
                className="modalTransferConfirm"
                onClick={() => {
                  if (!selectedTransferUserId) {
                    showAlert("No user selected to transfer.", "info");
                  } else {
                    setShowConfirmTransferDialog(true);
                  }
                }}
              >
                Confirm Transfer
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog open={showConfirmTransferDialog}>
        <div className="admin-transfer-modal">
          <div className="admin-remove-modalContent-close">
            <Tooltip title="Close">
              <CloseIcon
                onClick={() => setShowConfirmTransferDialog(false)}
                sx={{
                  fontSize: "large",
                  color: "#545454",
                  cursor: "pointer",
                  "&:hover": {
                    color: "red",
                  },
                }}
              />
            </Tooltip>
          </div>

          <div className="admin-transfer-modalContent-p">
            <h3>Confirm Admin Transfer</h3>
            <p>
              Super Admin rights will be transferred to{" "}
              <strong>
                {
                  adminUsers.find(
                    (user: any) => user.id === selectedTransferUserId
                  )?.name
                }
              </strong>{" "}
              and you will retain admin rights."
            </p>
            <p>Please confirm you want to proceed?</p>
          </div>

          <div className="admin-transfer-modalContent-Buttons">
            <button
              className="admin-transfer-modalCancel"
              onClick={() => {
                setShowConfirmTransferDialog(false);
                setShowTransferAdminDialog(true); // Show previous dialog
              }}
            >
              Cancel
            </button>
            <button
              className="admin-transfer-modalConfirm"
              onClick={handleTransferAdminConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>

      <NotificationDialog
        openAlert={openAlert}
        severity={severity}
        testMessage={testMessage}
        onCloseAlert={() => {
          setOpenAlert(false);
          setTestMessage("");
        }}
      />
    </div>
  );
};

export default AdminList;
