import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation
import styles from './header.module.css';
import { Tooltip, Menu, MenuItem, IconButton, CircularProgress, Typography } from "@mui/material";
import {serverEndPoint} from "./ServerCall/EnvironmentVariables";


const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const navigate = useNavigate();

  // State to store user details
  const [user, setUser] = useState({
    firstName: '',
    lastName:'',
    email: '',
    avatar: '', // Default avatar
  });
  
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch user details from the API
  useEffect(() => {
    const token =localStorage.getItem('accessToken')
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`${serverEndPoint}user-details`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            // Include authentication headers if required
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        
        // Assuming the API returns data in the following format:
        // { name: 'John Doe', email: 'john.doe@example.com', avatar: '/path/to/avatar.png' }
        setUser({
          firstName: data.firstName || ' ',
          lastName: data.lastName||'',
          email: data.email || 'N/A',
          avatar: data.profileImage && data.profileImage.trim() !== '' ? `data:image/jpeg;base64,${data.profileImage}` : '/default.png',
        });
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  const handleMenuOpen = (event:any) => {
    if (isOpen) {
      handleMenuClose();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <header className={styles.header}>

      <div className={styles.nav}>        
        <div>
          <IconButton 
            onClick={handleMenuOpen}
            aria-haspopup="true"
            aria-expanded={isOpen ? "true" : "false"}
            sx={{
              cursor: "pointer",
              padding: "0",
              marginRight: "15px",
              "&:hover": {
                cursor: "pointer"
              },
            }} >
            <img 
              src={user.avatar} 
              alt="Admin"  
              style={{ 
                border: "1px solid gray",
                borderRadius: "50px",
                verticalAlign: "center",
                textAlign: "center",
                width: "26px", height: "26px",
              }}
            />
          </IconButton>

          <Menu 
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                width: "280px",
                transform: "translate(10px, 7px)",           
                backgroundColor: "white",
                border: "1px solid transparent",
                color: "#616164",
                fontFamily: "Axiforma ExtraBold,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,sans-serif",
              },
            }}
          >
            <div 
              style={{
                height: "200px", 
                border: "1px solid transparent", 
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                marginBottom: "0px",
                marginTop: "-8px"
              }}
            >
              {loading ? (
                <CircularProgress color="inherit" 
                sx={{ 
                  display: 'block', 
                  margin: '20px auto', 
                  color: '#007BFF',
                  width: '50px !important', 
                  height: '50px !important'
                }} />
              ) : error ? (
                <Typography variant="body1" color="error"
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  marginTop: '10px',
                  fontSize: '0.8rem',
                }}>
                  {error}
                </Typography>
              ) : (
                <div>
                  <img 
                    src={user.avatar} 
                    alt="Admin" 
                    className="adminImage"
                    style={{ 
                      border: "1px solid gray",
                      borderRadius: "50px",
                      marginBottom: "10px",
                      marginTop: "30px",
                      width: "60px", height: "60px",
                    }}
                  />
                <div className='header-name-email-container' 
                 style={{
                    border: "2px solid transparent",
                    width: "260px",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginLeft: "10px",
                    height: "80px",
                    color: "#616164"
                }}>
                  <div className='user-name'
                    style={{
                      height: "30px",
                      border: "1px solid transparent",
                      marginBottom: "0px",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#616164"
                    }}
                  >
                   {`${user.firstName} ${user.lastName}`}
                  </div>

                  <div 
                    style={{
                      height: "30px",
                      border: "1px solid transparent",
                      color: "#616164"
                    }}
                  >
                    {user.email}
                  </div>
                  </div>
                </div>
              )}
            </div>
              
            <MenuItem  onClick={() => { handleMenuClose(); navigate('/update-profile'); }} style={{height: "50px"}}>
              <Link to="/update-profile" className="menuLink"  onClick={() => { handleMenuClose(); }}  
                style={{ 
                  textDecoration: 'none', 
                  color: 'inherit',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <img 
                  src="/update.png" 
                  alt="Update Profile" 
                  style={{
                    marginRight: "5px", width: "20px", height: "20px"
                  }}
                />
                <span>Update Profile</span>
              </Link>
            </MenuItem>

            <MenuItem 
            onClick={() => { 
              handleMenuClose(); 
              navigate('/logout'); 
            }} 
            style={{
              height: "50px",
              alignItems: "center",
            }}
          >
            <Link to="/logout" className="menuLink" 
              style={{ 
                textDecoration: 'none', 
                color: 'inherit',
                display: 'flex',
                alignItems: 'center'
              }}>
              <img 
                src="/logout.png" 
                alt="Logout"   
                style={{
                  marginRight: "8px",
                  transform: "translateY(1px)",
                  width: "17px",
                  height: "14px"
                }}
              />
              <span>Logout</span>
            </Link>
          </MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default Header;
