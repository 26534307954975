// This component houses Sample records for selected table from dataset
// This sample records can be view as
// 	- Full table with all fields and values
// 	- Just column names of table

// Table columns are draggable. These dragged table columns are then dropped into dropzones

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { SampleRecordsState } from "../../redux/SampleTableRecords/SampleTableRecordsInterfaces";
import { TabTileStateProps, TabTileStateProps2 } from "../../redux/TabTile/TabTilePropsInterfaces";
import { Box } from "./Box";
import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from "@mui/material";
import { deleteSavedCalculation, editSavedCalculation } from "../../redux/Calculations/CalculationsActions";
import { addTableRecords } from "../../redux/SampleTableRecords/SampleTableRecordsActions";
import {serverEndPoint} from "../ServerCall/EnvironmentVariables";


interface DisplayTableProps {
	dsId: string;
	table: any;
	tableRecords: any;
	tabTileProps: TabTileStateProps;
	calculations: any;
	editCalculationFunc: any;
	deleteCalculationFunc: any;
	addRecords: any;
}

const DisplayTable = ({
	// props
	dsId,
	table,

	// state
	tableRecords,
	tabTileProps,
	calculations,
	editCalculationFunc,
	deleteCalculationFunc,
	addRecords
}: DisplayTableProps) => {
	var SampleRecords: any = tableRecords?.[dsId]?.[table];
	const [columnsData, setColumnsData] = useState<any[]>([]);

	const propKey = `${tabTileProps.selectedTabId}.${tabTileProps.selectedTileId}`;
	const savedCalculations = calculations?.properties[propKey]?.savedCalculations;

	const savedNonAggregatedCalculations = calculations?.properties[propKey]?.savedCalculations?.filter((calculation: any) => !calculation.isAggregated);

	const tableRef = useRef<any | HTMLDivElement>(null);

	useEffect(() => {

		const lastChild = tableRef.current?.lastElementChild;
		if (lastChild) {
			lastChild.scrollIntoView({ behavior: 'smooth', inline: 'end' });
		}

	}, [savedNonAggregatedCalculations?.length])

	const formatFieldsData = () => {
		let _fieldsData: any[] = [];
		if (SampleRecords) {
			var tableKeys = Object.keys(SampleRecords[0]);
			var dataType = tableRecords.recordsColumnType[dsId][table];

			for (let i = 0; i < tableKeys.length; i++) {
				_fieldsData.push({
					fieldname: tableKeys[i],
					displayname: tableKeys[i],
					dataType: dataType?.filter((sc: any) => sc.columnName === tableKeys[i])[0]
						.dataType,
					tableId: table,
				});
			}
			return _fieldsData;
		}
		return _fieldsData;
	};

	const prepareInputs = () => {

		let _fields = formatFieldsData();

		console.log("Before sort", _fields)

		_fields = _fields.sort((a, b) => {

			const isASaved = savedCalculations?.find((calc: any) => {
				return calc.calculationInfo.calculatedFieldName.toLowerCase().replace(/[^a-z0-9]/g, '') === a.fieldname.toLowerCase().replace(/[^a-z0-9]/g, '')
			})

			const isBSaved = savedCalculations?.find((calc: any) => {
				return calc.calculationInfo.calculatedFieldName.toLowerCase().replace(/[^a-z0-9]/g, '') === b.fieldname.toLowerCase().replace(/[^a-z0-9]/g, '')
			})

			if (isASaved || isBSaved) {
				console.log({
					a: a.fieldname,
					b: b.fieldname,
					isASaved,
					isBSaved
				})
			}

			if (isASaved && !isBSaved) return 1;

			if (!isASaved && isBSaved) return -1;

			return 0;

		})

		console.log("After sort", _fields)

		setColumnsData(_fields);
	};

	useEffect(() => {
		prepareInputs();
	}, [SampleRecords]);

	// Get the column names from first row of table data
	const getKeys = (record: any) => {
		return Object.keys(record);
	};

	// Get the column names from getKeys() and render the header for table

	const GetHeaders: any = () => {

		// console.log({
		// 	SampleRecords,
		// 	columnsData,
		// })

		if (SampleRecords) {
			let keys = getKeys(SampleRecords[0]);
			keys = keys.sort((a, b) => {

				const isASaved = savedCalculations?.find((calc: any) => calc.calculationInfo.calculatedFieldName.toLowerCase().replace(/[^a-z0-9]/g, '') === a.toLowerCase().replace(/[^a-z0-9]/g, '')) ? true : false
				const isBSaved = savedCalculations?.find((calc: any) => calc.calculationInfo.calculatedFieldName.toLowerCase().replace(/[^a-z0-9]/g, '') === b.toLowerCase().replace(/[^a-z0-9]/g, '')) ? true : false

				if (isASaved && !isBSaved) return 1;
				if (!isASaved && isBSaved) return -1;
				return 0;

			});

			return keys.map((key: any, index: number) => {

				const item = columnsData[index];

				const isSavedCalculation = savedCalculations?.find((calc: any) => {

					// TODO: this has to be optimised, this is making everything super duper slow
					if (item && item.fieldname) {
						return calc.calculationInfo.calculatedFieldName.toLowerCase().replace(/[^a-z0-9]/g, '') === item.fieldname.toLowerCase().replace(/[^a-z0-9]/g, '');
					}
					return false;
				})

				return (
					<TableCell key={`${index}_${key}`}>
						<Box
							allSavedCalculations={savedCalculations}
							name={key}
							type={isSavedCalculation ? "calculation" : "card"}
							fieldData={columnsData[index]}
							colsOnly={false}
							isSavedCalculation={isSavedCalculation}
							handleEditButton={() => {
								editCalculationFunc(propKey, columnsData[index].fieldname)
							}}
							handleDeleteButton={async () => {

								// check if the calculation is aggregated or not. if it is not an aggregated we also have to update the table records
								if (!columnsData[index].isAggregated) {
									// console.log("Calculation is not aggregated, hence not deleting")

									const allPreviousSavedNonAggregatedCalculations = [...(calculations.properties[propKey]?.savedCalculations?.filter((calculation: any) => !calculation.isAggregated).map((calculation: any) => calculation.calculationInfo))]
									// console.log("allPreviousSavedNonAggregatedCalculations", allPreviousSavedNonAggregatedCalculations)

									const indexOfToBeDeletedCalculation = allPreviousSavedNonAggregatedCalculations.findIndex((calc: any) => {
										return calc.calculatedFieldName.toLowerCase().replace(/[^a-z0-9]/g, '') === columnsData[index].fieldname.toLowerCase().replace(/[^a-z0-9]/g, '')
									})

									// console.log("indexOfToBeDeletedCalculation", indexOfToBeDeletedCalculation)

									allPreviousSavedNonAggregatedCalculations.splice(indexOfToBeDeletedCalculation, 1)

									// console.log("allPreviousSavedNonAggregatedCalculations after deletion", allPreviousSavedNonAggregatedCalculations)

									if (allPreviousSavedNonAggregatedCalculations.length > 0) {

										const records = await fetch(
											`${serverEndPoint}sample-records?workspaceId=m9IRdLziyPk&databaseId=VUWoomoyaeC&datasetId=v8K8u5QX63w&recordCount=100&database=landmark&schema=pos&table=point_of_sales&tableId=pos`,
											{
												method: "POST",
												headers: {
													"Content-Type": "application/json",
													Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
												},
												body: JSON.stringify(allPreviousSavedNonAggregatedCalculations),
											}
										);

										const headers = await fetch(
											`${serverEndPoint}metadata-columns/VUWoomoyaeC?workspaceId=m9IRdLziyPk&database=landmark&schema=pos&table=point_of_sales`,
											{
												method: "POST",
												headers: {
													"Content-Type": "application/json",
													Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
												},
												body: JSON.stringify(allPreviousSavedNonAggregatedCalculations),
											}
										);

										if (records.status === 200 && headers.status === 200) {
											const recordsData = await records.json();
											const headersData = await headers.json();

											// TODO: use correct data set id here i guess
											addRecords("v8K8u5QX63w", "pos", recordsData, headersData);
										}

									} else {

										const records = await fetch(
											`${serverEndPoint}sample-records?workspaceId=m9IRdLziyPk&databaseId=VUWoomoyaeC&datasetId=v8K8u5QX63w&recordCount=100&database=landmark&schema=pos&table=point_of_sales&tableId=pos`,
											{
												method: "POST",
												headers: {
													"Content-Type": "application/json",
													Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
												}
											}
										);

										const headers = await fetch(
											`${serverEndPoint}metadata-columns/VUWoomoyaeC?workspaceId=m9IRdLziyPk&database=landmark&schema=pos&table=point_of_sales`,
											{
												method: "POST",
												headers: {
													"Content-Type": "application/json",
													Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
												}
											}
										);

										if (records.status === 200 && headers.status === 200) {
											const recordsData = await records.json();
											const headersData = await headers.json();

											// TODO: use correct data set id here i guess
											addRecords("v8K8u5QX63w", "pos", recordsData, headersData);
										}

									}

								}

								deleteCalculationFunc(columnsData[index].fieldname, propKey)
							}}
						/>
					</TableCell>
				);
			});
		} else return null;
	};

	// Render a single row of the table
	const RenderRow = (props: any) => {
		return props.keys.map((key: any, index: number) => {
			// Convert boolean values to "True" or "False"
			const value = typeof props.data[key] === "boolean" 
				? props.data[key] ? "True" : "False" 
				: props.data[key];
			return <TableCell key={`${index}_${key}`}>{value}</TableCell>;
		});
	};

	// Get all rows data and pass it to RenderRow to display table data
	const getRowsData = () => {
		if (SampleRecords) {
			let keys = getKeys(SampleRecords[0]);
			keys = keys.sort((a, b) => {

				// check if a is a saved calculation if so return -1
				const isASaved = savedCalculations?.find((calc: any) => calc.calculationInfo.calculatedFieldName.toLowerCase().replace(/[^a-z0-9]/g, '') === a.toLowerCase().replace(/[^a-z0-9]/g, '')) ? true : false
				const isBSaved = savedCalculations?.find((calc: any) => calc.calculationInfo.calculatedFieldName.toLowerCase().replace(/[^a-z0-9]/g, '') === b.toLowerCase().replace(/[^a-z0-9]/g, '')) ? true : false

				if (isASaved && !isBSaved) return 1;
				if (!isASaved && isBSaved) return -1;
				return 0;
			});

			return SampleRecords.map((row: any, index: number) => {
				return (
					<TableRow
						sx={{
							"& .MuiTableCell-root": {
								borderBottom: "0px",
							},
						}}
						key={index}
					>
						<RenderRow key={index} data={row} keys={keys} />
					</TableRow>
				);
			});
		} else return null;
	};

	const RenderButtons: any = () => {
		if (SampleRecords) {
			var keys = getKeys(SampleRecords[0]);
			return keys.map((key: any, index: number) => {
				return (
					<button
						key={key}
						className="boxContainer"
						draggable="true"
					// onDragStart={(e) => handleDragStart(e, columnsData[index])}
					>
						<Box
							name={key}
							type="card"
							fieldData={columnsData[index]}
							colsOnly={true}
						/>
					</button>
				);
			});
		} else return null;
	};

	return tabTileProps.columnsOnlyDisplay ? (
		<div className="showColumnsOnly">
			<RenderButtons />
		</div>
	) : (
		<TableContainer
			ref={tableRef}
			sx={{
				height: "100%",
				overflow: "hidden",
				paddingLeft: "10px",
				// width: "fit-content",
				"&:hover": {
					overflow: "auto",
				},
				"::-webkit-scrollbar": {
					width: "5px",
					height: "5px"
				},
			}}
		>
			<Table stickyHeader={true} sx={{ width: "fit-content" }}>
				<TableHead
					sx={{
						"& .MuiTableCell-root": {
							fontSize: "12px",
							fontWeight: "600",
							color: "rgb(87, 87, 87)",
							padding: "2px 9px 7px 0px ",
							backgroundColor: "white",
							lineHeight: "normal",
							letterSpacing: "normal",
							fontFamily:
								" -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
						},
					}}
				>
					<TableRow
						sx={{
							"& .MuiTableCell-root": {
								borderBottom: "0px",
							},
						}}
					>
						<GetHeaders />
					</TableRow>
				</TableHead>
				<TableBody
					sx={{
						"& .MuiTableCell-root": {
							fontSize: "12px",
							padding: "0px 10px 0px 25px ",
							whiteSpace: "nowrap",
							maxWidth: "250px",
							minWidth: "75px",
							textOverflow: "ellipsis",
							overflow: "hidden",
							backgroundColor: "white",
							fontFamily:
								" -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
						},
					}}
				>
					{getRowsData()}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const mapStateToProps = (state: SampleRecordsState & TabTileStateProps2 & any, ownProps: any) => {
	return { tableRecords: state.sampleRecords, tabTileProps: state.tabTileProps, calculations: state.calculations };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		editCalculationFunc: (propKey: string, calculationFieldName: string) => dispatch(editSavedCalculation(propKey, calculationFieldName)),
		deleteCalculationFunc: (calculationFieldName: string, propKey: string) => dispatch(deleteSavedCalculation(calculationFieldName, propKey)),
		addRecords: (
			id: string,
			tableId: string,
			tableRecords: any,
			columnType: any
		) => dispatch(addTableRecords(id, tableId, tableRecords, columnType)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayTable);
