// for LOCAL-ONLY TWO TIER - separate front end & backend
// export const serverEndPoint = "http://localhost:8080/api/";

// for SERVER+LOCAL TWO TIER - separate front end & backend
export const serverEndPoint = "https://app.qa.silzila.com/api/";
export const localEndPoint = "https://qa.silzila.com/";
export const qaEndPoint = 'app.qa.silzila.com/api/';
// for LOCAL-ONLY SINGLE TIER - single build with react app
// export const serverEndPoint = "http://localhost:8080/";

export const websiteAddress = "https://silzila.org";
export const githubAddress = "https://github.com/silzila/silzila";
export const githubIssueAddress = "https://github.com/silzila/silzila/issues";