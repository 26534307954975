"use client";
import React, { useState, useMemo, useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import "./groupDetail.css";
import "./allPages.css";
import { Tooltip, Dialog, AlertColor } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { NotificationDialog } from "./CommonFunctions/DialogComponents";
import useSWR, { mutate } from "swr";
import axios from "axios";
import {serverEndPoint} from "./ServerCall/EnvironmentVariables";


interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isDeleted: boolean;
  profileImage?: string;
}
interface Participant extends User {}

const GroupDetailsComponent = () => {
  const { groupId } = useParams<string>();
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>(true);
  const [selectAll, setSelectAll] = useState<boolean>(false); // Add state for Select All checkbox

  const location = useLocation();
  const group = location.state?.groupName;

  // Local state for UI interactions
  const [hoveredIndex, setHoveredIndex] = useState<any>(-1);
  const [searchQuery, setSearchQuery] = useState<string>(""); // For filtering participants
  const [showAddUsersDialog, setShowAddUsersDialog] = useState<boolean>(false);
  const [modalSelectedUserIds, setModalSelectedUserIds] = useState<number[]>(
    []
  );
  const [addUserSearchQuery, setAddUserSearchQuery] = useState<string>(""); // For filtering all users in modal
  const [userIdToDelete, setUserIdToDelete] = useState<number | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [userToRemove, setUserToRemove] = useState<Participant | null>(null);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [testMessage, setTestMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("success");
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  }>({ key: "name", direction: "asc" });

  // Fetcher function for SWR using axios
  const fetcher = (url: string) =>
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/json",
          "X-TENANT-ID": "silzila",
        },
      })
      .then((res) => res.data);

  // SWR hooks for participants and all users with default values and explicit mutate functions
  const {
    data: participants = [],
    error: participantsError,
    mutate: mutateParticipants,
  } = useSWR<Participant[]>(
    groupId
      ? `${serverEndPoint}groups/list/${encodeURIComponent(groupId)}`
      : null,
    fetcher,
    {
      onSuccess: () => {
        setIsLoading(false);
      },
    }
  );

  const {
    data: allUsers = [],
    error: allUsersError,
    mutate: mutateAllUsers,
  } = useSWR<User[]>(`${serverEndPoint}users/list`, fetcher, {
    onSuccess: () => {
      setIsLoading(false);
    },
  });

  // Handle adding users
  const handleAddUsers = () => {
    setShowAddUsersDialog(true);
  };

  const showAlert = (message: string, severity: AlertColor) => {
    setTestMessage(message);
    setSeverity(severity);
    setOpenAlert(true);
    setTimeout(() => {
      setOpenAlert(false);
    }, 3000);
  };

  const handleAddUsersConfirm = async () => {
    //// const userIds = modalSelectedUserIds; // Only include users not already in the group

    const userIds = selectedUsers;
    const token = localStorage.getItem("accessToken");
    console.log(selectedUsers);

    if (userIds.length === 0) {
      console.error("No new users selected to add.");
      showAlert("No new users selected to add.", "warning");
      return; // Exit if no new users are selected
    }

    try {
      const response = await axios.post(
        `${serverEndPoint}groups/adduser`,
        { userIds: userIds, groupId: parseInt(groupId as string) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-TENANT-ID": "silzila",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.message === "success") {
        // Revalidate participants data
        mutateParticipants();
        showAlert("User(s) added successfully!", "success");
        setModalSelectedUserIds([]); // Clear selection after adding
        setAddUserSearchQuery(""); // Clear search query
        setSelectedUsers([]);
      } else {
        showAlert(
          response.data.message || "Failed to add users to the group.",
          "error"
        );
        console.error("Error adding users to group:", response.data.message);
        setSelectedUsers([]);
      }
    } catch (error: any) {
      console.error("Error adding users to group:", error);
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while adding users.";
      showAlert(errorMessage, "error");
      setSelectedUsers([]);
    }
  };

  // Handle deleting users
  const deleteUser = (userId: number) => {
    const user = participants.find((participant) => participant.id === userId);
    if (user) {
      setUserToRemove(user);
      setUserIdToDelete(userId);
      setShowDeleteConfirmation(true);
    }
  };

  const handleMouseEnter = (id: number) => {
    setHoveredIndex(id);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  const confirmDeleteUser = async () => {
    if (userIdToDelete === null) return;
    const token = localStorage.getItem("accessToken");

    try {
      const response = await axios.delete(
        `${serverEndPoint}groups/removeuser`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-TENANT-ID": "silzila",
            "Content-Type": "application/json",
          },
          data: {
            userIds: [userIdToDelete],
            groupId: parseInt(groupId as string),
          },
        }
      );

      if (response.data.message === "success") {
        mutateParticipants();
        setSelectedUsers((prevSelectedUsers: number[]) =>
          prevSelectedUsers.filter((id: number) => id !== userIdToDelete)
        );
        showAlert("User removed successfully!", "success");
      } else {
        showAlert(
          response.data.message || "Failed to remove the user.",
          "error"
        );
        console.error("Error deleting user from group:", response.data.message);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while removing the user.";
      showAlert(errorMessage, "error");
      console.error("Error deleting user from group:", error);
    } finally {
      setShowDeleteConfirmation(false);
      setUserIdToDelete(null);
      setUserToRemove(null);
    }
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setUserIdToDelete(null);
    setUserToRemove(null);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleAddUserSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAddUserSearchQuery(e.target.value);
  };

  const filteredParticipants = useMemo(() => {
    if (!participants) return [];
    return participants.filter(
      (participant: Participant) =>
        participant.firstName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        participant.lastName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        participant.email.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [participants, searchQuery]);

  const isUserInGroup = (user: User) => {
    return participants.some(
      (participant: Participant) => participant.id === user.id
    );
  };

  const filteredAllUsers = useMemo(() => {
    let idCSV = participants.map((item: any) => item.id).join(",");

    if (!allUsers) return [];
    return allUsers
      .filter((usr: User) => !idCSV.includes(usr.id.toString()))
      .filter((user: User) => {
        return (
          (user.firstName
            .toLowerCase()
            .includes(addUserSearchQuery.toLowerCase()) ||
            user.lastName
              .toLowerCase()
              .includes(addUserSearchQuery.toLowerCase()) ||
            user.email
              .toLowerCase()
              .includes(addUserSearchQuery.toLowerCase())) &&
          !isUserInGroup(user) && // Filter out users already in the group
          !user.isDeleted // Exclude Deleted Users
        );
      });
  }, [allUsers, addUserSearchQuery, participants]);

  // Reset modal state when the modal is opened
  useEffect(() => {
    if (showAddUsersDialog) {
      setModalSelectedUserIds([]);
      setAddUserSearchQuery("");
      setSelectAll(false); // Reset selectAll state
      setSelectedUsers([]);
    }
  }, [showAddUsersDialog]);

  useEffect(() => {
    const allSelected = filteredParticipants.every((participant) =>
      selectedUsers.includes(participant.id)
    );
    setSelectAll(allSelected);
  }, [selectedUsers, filteredParticipants]);

  const handleAddUser = async () => {
    try {
      const userIds = selectedUsers;
      if (userIds.length === 0) {
        showAlert("No new users selected to add.", "warning");
        return;
      }
      await handleAddUsersConfirm();
      setTimeout(() => {
        setShowAddUsersDialog(false);
      }, 0);
    } catch (error) {
      showAlert("Failed to add user. Please try again.", "error");
    }
  };

  const toggleSelectUser = (id: number) => {
    setSelectedUsers((prevSelectedUsers: number[]) => {
      if (prevSelectedUsers.includes(id)) {
        return prevSelectedUsers.filter((userId: number) => userId !== id);
      } else {
        return [...prevSelectedUsers, id];
      }
    });
  };

  const handleDeselectAll = () => {
    if (selectedUsers.length > 0) {
      setSelectedUsers([]);
    }
  };

  // Function to select all users
  const selectAllUsers = () => {
    if (selectAll) {
      setSelectedUsers([]);
    } else {
      const allUserIds = filteredParticipants.map(
        (participant) => participant.id
      );
      setSelectedUsers(allUserIds);
    }
  };

  const handleCloseModal = () => {
    setShowAddUsersDialog(false);
    setSelectedUsers([]);
  };

  const sortedParticipants = useMemo(() => {
    if (!filteredParticipants) return [];
    let sortedItems = [...filteredParticipants];
    if (sortConfig.key) {
      sortedItems.sort((a, b) => {
        let aValue = "";
        let bValue = "";
        if (sortConfig.key === "name") {
          aValue = `${a.firstName} ${a.lastName}`.toLowerCase();
          bValue = `${b.firstName} ${b.lastName}`.toLowerCase();
        } else if (sortConfig.key === "email") {
          aValue = a.email.toLowerCase();
          bValue = b.email.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedItems;
  }, [filteredParticipants, sortConfig]);

  const handleSort = (key: string) => {
    const newDirection =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction: newDirection });
  };

  const getSortIcon = (key: any) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <img className="headerIcon-up" src="/sort-up.png" alt="Ascending" />
      ) : (
        <img
          className="headerIcon-down "
          src="/sort-down.png"
          alt="Descending"
        />
      );
    }
    return <img className="headerIcon" src="/sort.png" alt="Default" />;
  };

  if (participantsError || allUsersError)
    return (
      <div className="loading-error-info">
        <p className="loading-default-text">Error loading users.</p>
      </div>
    );

  return (
    <div className="group-container">
      <div
        className="heading"
        style={{
          height: "50px",
          border: "1px solid transparent",
          display: "flex",
          marginLeft: "15px",
          paddingTop: "5px",
        }}
      >
        <img
          src="/groups.png"
          alt="Group Details"
          style={{ width: "24px", height: "24px" }}
        />
        <div>
          <h3
            style={{
              color: "#616164",
              marginTop: "-4px",
              marginBottom: "5px",
              marginLeft: "6px",
            }}
          >
            Group Details
          </h3>
        </div>
      </div>

      {/*  <div className='group-back-link-container'>
             <Link to='/admin/groups'><button className='back-link-groupdetails'>Back</button></Link></div> */}

      <div className="group-button-add-search-container">
        <div className="group-actionButtons">
          <button className="group-button-user" onClick={handleAddUsers}>
            <img
              className="group-addIcon group-addIconDefault"
              src="/add_green.png"
              alt="Add Users Logo"
              style={{ width: "16px", height: "16px" }}
            />
            <img
              className="group-addIcon group-addIconHover"
              src="/add_white.png"
              alt="Add Users Logo Hover"
              style={{ width: "16px", height: "16px" }}
            />
            Add Users
          </button>
        </div>

        <div className="user-select-action-container">
          <div className="user-actions-container">
            {/* Future Update
                    <p  style={{ cursor: 'pointer' }} onClick={selectAllUsers}>
                    Select All
                    </p>

                    {selectedUsers.length > 0 && (
                    <div className="user-actions-count-container">    
                    <p onClick={handleDeselectAll} style={{ cursor: 'pointer', marginLeft: "10px"}}>Deselect All</p>
                    <span>{selectedUsers.length > 0 && `${selectedUsers.length} selected`}</span>
                    <p style={{ marginLeft: "10px" }}>Actions</p>
                    </div>)} */}
          </div>

          <div className="group-searchContainer">
            <input
              type="text"
              placeholder="Search participants"
              value={searchQuery}
              onChange={handleSearchChange}
              className="group-searchInput"
            />
            <img
              src="/glass.png"
              alt="Search Icon"
              className="group-searchIcon"
              style={{ width: "19px", height: "19px" }}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="loading-container">
          <div className="group-spinner"></div>
        </div>
      ) : sortedParticipants.length === 0 && searchQuery.length === 0 ? (
        <div className="no-user-info">
          <p>No participants found for this group.</p>
        </div>
      ) : (
        <div className="group-tableContainer">
          <table className="group-userTable">
            <thead>
              <tr>
                <th></th>

                <th></th>

                <th onClick={() => handleSort("name")}>
                  Name{" "}
                  <span className="icon-wrapper">{getSortIcon("name")}</span>
                </th>
                <th onClick={() => handleSort("email")}>
                  Email{" "}
                  <span className="icon-wrapper">{getSortIcon("email")}</span>
                </th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {sortedParticipants.length > 0 ? (
                sortedParticipants.map((participant: Participant) => (
                  <tr
                    key={participant.id}
                    onMouseEnter={() => handleMouseEnter(participant.id)}
                    onMouseLeave={handleMouseLeave}
                    className={
                      selectedUsers.includes(participant.id)
                        ? "selectedRow"
                        : ""
                    }
                  >
                    <td>
                      {/* <input
                        type="checkbox"
                        checked={selectedUsers.includes(participant.id)}
                        onChange={() => toggleSelectUser(participant.id)}
                        style={{ width: "16px", height: "16px" }}
                      /> */}
                    </td>

                    <td>
                      <img
                        src={
                          participant.profileImage
                            ? `data:image/jpeg;base64,${participant.profileImage}`
                            : "/default.png"
                        }
                        style={{ width: "20px", height: "20px", borderRadius: "50%"}}
                        alt={`${participant.firstName} ${participant.lastName}`}
                      />
                    </td>

                    <td>
                      {participant.firstName} {participant.lastName}
                    </td>

                    <td>{participant.email}</td>

                    <td>
                      <button
                        onClick={() => deleteUser(participant.id)}
                        style={{
                          background: "none",
                          border: "none",
                          padding: 0,
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip title="Remove User">
                          <img
                            src={
                              hoveredIndex === participant.id
                                ? "/cross_red.png"
                                : "/cross_white.png"
                            }
                            alt="Delete User"
                            style={{ width: "17px", height: "17px" }}
                          />
                        </Tooltip>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <div className="no-user-info">
                  <p className="loading-default-text">No group found.</p>
                </div>
              )}
            </tbody>
          </table>
        </div>
      )}

      <Dialog open={showAddUsersDialog} onClose={handleCloseModal}>
        <div className="group-modal">
          <div className="group-modal-content">
            <div className="group-header-section">
              <div className="group-modal-content-close">
                <Tooltip title="Close">
                  <CloseIcon
                    onClick={() => {
                      setShowAddUsersDialog(false);
                      setSelectedUsers([]);
                    }}
                    sx={{
                      fontSize: "large",
                      color: "#545454",
                      cursor: "pointer",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  />
                </Tooltip>
              </div>

              <h3 className="group-modal-title">
                Select users to add to the group:
              </h3>
              <div
                style={{
                  height: "30px",
                  border: "1px solid transparent",
                  marginTop: "10px",
                }}
              ></div>
              <div className="group-modal-search-container">
                <input
                  type="text"
                  placeholder="Search users"
                  value={addUserSearchQuery}
                  onChange={handleAddUserSearchChange}
                  className="group-modal-searchInput"
                />
                <img
                  src="/glass.png"
                  alt="Search Icon"
                  className="group-modal-searchIcon"
                />
              </div>
            </div>

            <div className="group-table-container">
              <table className="group-userList">
                <thead>
                  <tr>
                    <th>
                      {/* <input
                        style={{
                          position: "relative",
                          zIndex: 1,
                          height: "16px",
                          width: "16px",
                        }}
                        type="checkbox"
                        checked={selectAll}
                        onChange={() => {
                          handleDeselectAll();
                        }}
                      /> */}
                      {selectedUsers.length > 0 && (
                        <Tooltip title="Deselect All">
                          <span
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-48%, -50%)",
                              zIndex: 2,
                              pointerEvents: "none",
                            }}
                          >
                            <img
                              src="/hyphen.png"
                              alt="Hyphen Icon"
                              style={{ width: "42px", height: "34px" }}
                            />
                          </span>
                        </Tooltip>
                      )}
                    </th>

                    <th></th>
                    <th>Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAllUsers.length > 0 ? (
                    filteredAllUsers.map((user: any, index: number) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedUsers.includes(user.id)}
                            onChange={() => toggleSelectUser(user.id)}
                            style={{ width: "16px", height: "16px" }}
                          />
                        </td>
                        <td>
                          <img
                            src={
                              user.profileImage
                                ? `data:image/jpeg;base64,${user.profileImage}`
                                : "/default.png"
                            }
                            alt={user.firstName}
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                            }}
                          />
                        </td>
                        <td>
                          <span
                            className={
                              isUserInGroup(user) ? "disabled-text" : ""
                            }
                          >
                            {user.firstName} {user.lastName}
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              isUserInGroup(user) ? "disabled-text" : ""
                            }
                          >
                            {user.email}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <p>No user found</p>
                  )}
                </tbody>
              </table>
            </div>

            <div className="group-footer-section">
              <button
                onClick={() => {
                  // handleAddUsersConfirm();
                  handleAddUser();
                }}
                style={{ marginTop: "0px" }}
                className="modal-add-users-btn"
              >
                Add Users
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      <NotificationDialog
        openAlert={openAlert}
        severity={severity}
        testMessage={testMessage}
        onCloseAlert={() => {
          setOpenAlert(false);
          setTestMessage("");
        }}
      />

      <Dialog
        open={showDeleteConfirmation && !!userToRemove}
        onClose={cancelDelete}
      >
        {userToRemove && (
          <div className="group-remove-modal">
            <div className="group-remove-modalContent-close">
              <Tooltip title="Close">
                <CloseIcon
                  onClick={cancelDelete}
                  sx={{
                    fontSize: "large",
                    color: "#545454",
                    cursor: "pointer",
                    "&:hover": {
                      color: "red",
                    },
                  }}
                />
              </Tooltip>
            </div>
            <div className="group-remove-modalContent-p">
              <p>
                {" "}
                Are you sure you want to remove{" "}
                <strong>
                  {userToRemove.firstName} {userToRemove.lastName}
                </strong>
                ?
              </p>
            </div>
            <div className="group-remove-modal-buttons">
              <button onClick={cancelDelete} className="group-modal-cancel">
                Cancel
              </button>
              <button
                onClick={confirmDeleteUser}
                className="group-modal-confirm"
              >
                Confirm
              </button>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default GroupDetailsComponent;
