import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useSWR from "swr";
import "./groups.css";
import "./allPages.css";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import {serverEndPoint} from "./ServerCall/EnvironmentVariables";


const fetcher = (url: any, axiosInstance: any) =>
  axiosInstance.get(url).then((res: any) => res.data);

const GroupName = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [hoveredIndex, setHoveredIndex] = useState<any>(-1);
  const [sortConfig, setSortConfig] = useState<any>({
    key: "groupName",
    direction: "asc",
  });
  const [groupIndexToDelete, setGroupIndexToDelete] = useState<any>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<any>(false);
  const [groupToDelete, setGroupToDelete] = useState<any>(null);
  const [groupToRename, setGroupToRename] = useState<any>(null);
  const [newGroupName, setNewGroupName] = useState<any>("");
  const [showCreateGroupDialog, setShowCreateGroupDialog] =
    useState<any>(false);
  const [renameError, setRenameError] = useState<any>("");
  const [isLoading, setIsLoading] = useState<any>(true);

  const accessToken = localStorage.getItem("accessToken");

  const axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "X-TENANT-ID": "silzila",
      "Content-type": "application/json",
    },
  });

  const {
    data: groups = [],
    mutate,
    error,
  } = useSWR(`${serverEndPoint}groups/list`, (url) =>
    fetcher(url, axiosInstance)
  );
  useEffect(() => {
    if (groups.length > 0 || groups.length === 0) {
      setIsLoading(false);
    }
  }, [groups]);

  if (error) {
    setIsLoading(false);
    console.error("Error fetching groups:", error);
  }

  const filteredGroups: any = groups
    .filter((group: any) =>
      group.groupName.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a: any, b: any) => {
      if (a.id === 1) return -1;
      if (b.id === 1) return 1;
      let aValue, bValue;
      if (sortConfig.key === "groupName") {
        aValue = a.groupName.toLowerCase();
        bValue = b.groupName.toLowerCase();
      } else if (sortConfig.key === "userCount") {
        aValue = a.userCount;
        bValue = b.userCount;
      }
      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });

  const deleteGroup = (index: any) => {
    setGroupIndexToDelete(index);
    setGroupToDelete(groups.index);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteGroup = async () => {
    try {
      const groupToDelete = filteredGroups[groupIndexToDelete];
      if (!groupToDelete.id) throw new Error("Group ID is missing");

      await axiosInstance.delete(
        `${serverEndPoint}groups/${groupToDelete.id}`
      );
      mutate();
    } catch (error) {
      console.error("There was an error deleting the group:", error);
      alert("Error deleting group. Please try again.");
    } finally {
      setShowDeleteConfirmation(false);
      setGroupIndexToDelete(null);
    }
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setGroupIndexToDelete(null);
  };

  const handleMouseEnter = (index: any) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  const handleGroupClick = (groupid: any, groupName: any) => {
    navigate(`/group-details/${groupid}`, { state: { groupName } });
    console.log(groupName);
  };

  const handleSort = (key: any) => {
    const newDirection =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction: newDirection });
  };

  const getSortIcon = (key: any) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <img className="headerIcon-up" src="/sort-up.png" alt="Ascending" />
      ) : (
        <img
          className="headerIcon-down "
          src="/sort-down.png"
          alt="Descending"
        />
      );
    }
    return <img className="headerIcon" src="/sort.png" alt="Default" />;
  };

  const handleCreateGroup = async () => {
    if (!newGroupName.trim()) {
      alert("Group name cannot be empty.");
      return;
    }

    try {
      await axiosInstance.post(
        `${serverEndPoint}groups/${newGroupName}`
      );
      mutate(); // Refetch the groups to update the list

      setShowCreateGroupDialog(false);
      setNewGroupName("");
    } catch (error) {
      console.error("Error creating group:", error);
      alert("Error creating group. Please try again.");
    }
  };

  const handleGroupNameChange = (e: any) => {
    setNewGroupName(e.target.value);
  };

  const handleCloseDialog = () => {
    setShowCreateGroupDialog(false);
    setNewGroupName("");
  };

  const handleRenameClick = (group: any) => {
    setGroupToRename(group);
    setNewGroupName(group.groupName);
  };

  const handleRenameCancel = () => {
    setGroupToRename(null);
    setNewGroupName("");
    setRenameError("");
  };

  const handleRenameConfirm = async () => {
    if (!newGroupName.trim()) {
      setRenameError("Group name cannot be empty");
      return;
    }

    try {
      await axiosInstance.put(`${serverEndPoint}groups/update`, {
        id: groupToRename.id,
        name: newGroupName,
      });

      mutate(); // Refetch the groups to update the list
      setGroupToRename(null);
      setNewGroupName("");
      setRenameError("");
    } catch (error) {
      console.error("Error renaming group:", error);
      alert("Error renaming group. Please try again.");
    }
  };

  if (error)
    return (
      <div className="loading-error-info">
        <p className="loading-default-text">Error loading Geoups.</p>
      </div>
    );

  return (
    <div className="groupName-container">
      <div
        className="heading"
        style={{
          height: "50px",
          border: "1px solid transparent",
          display: "flex",
          marginLeft: "15px",
          paddingTop: "5px",
        }}
      >
        <img
          src="/groups.png"
          alt="Groups"
          style={{ width: "24px", height: "24px" }}
        />
        <div>
          <h3
            style={{
              color: "#616164",
              marginTop: "-4px",
              marginBottom: "5px",
              marginLeft: "6px",
            }}
          >
            Groups
          </h3>
        </div>
      </div>

      {/*  <div className='groupName-back-link-container'>
                <Link to='/'><button className='back-link-groupName'>Back</button></Link>
            </div> */}

      <div className="groupName-button-add-search-container">
        <div className="groupName-actionButtons">
          <button
            className="groupName-button-group"
            // onClick={() => setShowCreateGroupDialog(true)}
            onClick={() => setShowCreateGroupDialog(true)}
          >
            <img
              className="groupName-addIcon groupName-addIconDefault"
              src="/groups_green.png"
              alt="Create Group"
              style={{ width: "15px", height: "15px" }}
            />
            <img
              className="groupName-addIcon groupName-addIconHover"
              src="/groups_white.png"
              alt="Create Group"
              style={{ width: "15px", height: "15px" }}
            />
            Create Group
          </button>
        </div>

        <div className="user-select-action-container">
          <div className="user-actions-container"></div>

          <div
            className="groupName-searchContainer"
            style={{ paddingTop: "1px" }}
          >
            <input
              type="text"
              placeholder="Search Groups"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="groupName-searchInput"
            />
            <img
              src="/glass.png"
              alt="Search Icon"
              className="groupName-searchIcon"
              style={{ width: "19px", height: "19px" }}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="groupName-loading-container">
          <div className="groupName-spinner"></div>
        </div>
      ) : filteredGroups.length === 0 && searchQuery.length === 0 ? (
        <div className="no-user-info">
          <p className="loading-default-text">
            No groups have been created yet.
          </p>
        </div>
      ) : (
        // {filteredGroups.length === 0 ? (
        //     <p>No groups have been created yet.</p>
        // ) : (
        <div className="groupName-tableContainer">
          <table className="groupName-groupsTable">
            <thead>
              <tr>
                <th onClick={() => handleSort("groupName")}>
                  Group Name{" "}
                  <span className="icon-wrapper">
                    {getSortIcon("groupName")}
                  </span>
                </th>
                <th onClick={() => handleSort("userCount")}>
                  Total Users{" "}
                  <span className="icon-wrapper">
                    {getSortIcon("userCount")}
                  </span>
                </th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {filteredGroups.length > 0 ? (
                filteredGroups.map((group: any, index: any) => (
                  <tr
                    key={group.id}
                    onClick={() => handleGroupClick(group.id, group.groupName)}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    className={groups.includes(index) ? "selectedRow" : ""}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="nameHyperlink">
                      {group.isAdmin ? (
                        <Tooltip title="Admin">
                          <img
                            src="/crown.png"
                            alt="Admin Icon"
                            style={{
                              transform: "translateY(3px)",
                              width: "15px",
                              height: "15px",
                              marginRight: "15px",
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <span
                          style={{
                            display: "inline-block",
                            width: "15px",
                            height: "15px",
                            marginRight: "15px",
                          }}
                        ></span>
                      )}
                      {group.id === 1
                        ? `(${group.groupName})`
                        : group?.groupName}
                    </td>
                    <td>{group.userCount}</td>
                    <td
                      className={`groupName-action-container ${
                        index === 2 ? "first-row-actions" : ""
                      }`}
                      style={{ verticalAlign: "center", textAlign: "center" }}
                    >
                      {group.id !== 1 && (
                        <div className="group-img-icon">
                          <button
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent triggering the row click
                              deleteGroup(index);
                            }}
                            style={{
                              background: "none",
                              border: "none",
                            }}
                          >
                            <Tooltip title="Delete Group">
                              <img
                                src={
                                  hoveredIndex === index
                                    ? "/delete_red.png"
                                    : "/delete_white.png"
                                }
                                alt="Delete Group"
                                style={{
                                  marginBottom: "-2px",
                                  width: "17px",
                                  height: "17px",
                                }}
                              />
                            </Tooltip>
                          </button>

                          <button
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent triggering the row click
                              handleRenameClick(group);
                            }}
                            style={{
                              background: "none",
                              border: "none",
                              padding: 0,
                              cursor: "pointer",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                          >
                            <Tooltip title="Rename Group Name">
                              <img
                                src={
                                  hoveredIndex === index
                                    ? "/edit.png"
                                    : "/edit_white.png"
                                }
                                alt="Rename Group Name"
                                style={{
                                  marginTop: "1px",
                                  width: "14px",
                                  height: "15px",
                                }}
                              />
                            </Tooltip>
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <div className="no-user-info">
                  <p className="loading-default-text">No group found.</p>
                </div>
              )}
            </tbody>
          </table>
        </div>
      )}

      {showDeleteConfirmation && (
        <div className="groupName-modal">
          <div className="groupName-modal-content">
            <span className="groupName-close" onClick={cancelDelete}>
              &times;
            </span>
            <p>
              Are you sure you want to delete {groupToDelete?.groupName} Group?
            </p>
            <div className="groupName-modal-buttons">
              <button onClick={cancelDelete} className="groupName-modal-cancel">
                Cancel
              </button>
              <button
                onClick={confirmDeleteGroup}
                className="groupName-modal-create"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showCreateGroupDialog && (
        <div className="groupName-modal">
          <div className="groupName-modal-content">
            <span className="groupName-close" onClick={handleCloseDialog}>
              &times;
            </span>
            <p>Create Group</p>
            <input
              className="groupName-input"
              type="text"
              placeholder="Enter group name"
              value={newGroupName}
              onChange={handleGroupNameChange}
            />
            <div className="groupName-modal-buttons">
              <button
                className="groupName-modal-cancel"
                onClick={handleCloseDialog}
              >
                Cancel
              </button>
              <button
                className="groupName-modal-create"
                onClick={handleCreateGroup}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {groupToRename && (
        <div className="groupName-modal">
          <div className="groupName-modal-content">
            <span className="groupName-close" onClick={handleRenameCancel}>
              &times;
            </span>
            <p>Rename Group: {groupToRename.groupName}</p>
            <input
              className="groupName-input"
              type="text"
              value={newGroupName}
              onChange={handleGroupNameChange}
            />
            {renameError && <p className="groupName-error">{renameError}</p>}
            <div className="groupName-modal-buttons">
              <button
                onClick={handleRenameCancel}
                className="groupName-modal-cancel"
              >
                Cancel
              </button>
              <button
                onClick={handleRenameConfirm}
                className="groupName-modal-create"
              >
                Rename
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupName;
