import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@mui/styles";

const dropDownStyles = makeStyles({
  customSelect: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#2bb9bb",
      },
    },
  },
});

const DropDownForPattern = ({
  items,
  exprType = "exprType",
  value,
  handleDropDownChange,
}: {
  items: any;
  exprType: string;
  value: any;
  handleDropDownChange: (e: any, key: string) => void;
}) => {
  return (
    <FormControl
      fullWidth
      size="small"
      className={dropDownStyles().customSelect}
      // sx={{ margin: "4px" }}
    >
      <Select
        sx={{
          height: "1.5rem",
          fontSize: "14px",
          textAlign: "left",
          ".MuiSelect-icon": {
            fontSize: "19px",
            marginLeft: "20px",
            color: "#999999",
            right: "3.5px",
          },
        }}
        IconComponent={KeyboardArrowDownIcon}
        onChange={(e) => {
          handleDropDownChange(e, exprType);
        }}
        value={value}
      >
        {items.map((item: any) => {
          return (
            <MenuItem key={item.key} value={item.key}>
              
              <Typography
                component="div"
                sx={{
                  display: "grid",
                  gridTemplateColumns: "20px auto", // Fixed width for icon, auto for text
                  alignItems: "center",
                  fontSize: "12px",
                  whiteSpace: "nowrap", 
                }}
              >
                <span style={{fontSize: "12px", }}>
                  {item.value.split(" ")[0]} 
                </span >
                <span style={{fontSize: "12px", }}>{item.value.split(" ").slice(1).join(" ")}</span>{" "}
                {/* Remaining text */}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default DropDownForPattern;
