import LabelFormatingForRichText from "./LabelFormatingForRichText";

const ChartFormatForDm = () => {
	return (
		<div className="optionsInfo">
			<LabelFormatingForRichText/>
		</div>
	);
};

export default ChartFormatForDm;
