// This component is the draggable table column are then dropped into dropzones

import React from "react";
import { useDrag } from "react-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { AiOutlineFunction } from "react-icons/ai";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from "react-icons/md";

export const Box = ({
	name,
	type,
	fieldData,
	colsOnly,
	isSvedClculation,
	handleEditButton,
	handleDeleteButton,
	allSavedCalculations,
	propKey
}: any) => {

	let boxUUID: string | null

	if (isSvedClculation) {
		boxUUID = allSavedCalculations.find((calc: any) => calc.calculationInfo.calculatedFieldName.toLowerCase().replace(/[^a-z0-9]/g, '') === fieldData.fieldname.toLowerCase().replace(/[^a-z0-9]/g, ''))?.uuid
	} else {
		boxUUID = null
	}

	const [opacity, drag] = useDrag<any>({
		type: type??'card',
		item: { name, type, fieldData, bIndex: 99, boxUUID },
		collect: (monitor: any) => ({
			opacity: monitor.isDragging() ? 0.4 : 1,
		}),
	});

	const [isDivHoverActive, setIsDivHoverActive] = React.useState(false);
	const [arrowDirection, setArrowDirection] = React.useState<string>("down");
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div onMouseEnter={() => setIsDivHoverActive(true)} onMouseLeave={() => setIsDivHoverActive(false)} ref={drag} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", maxWidth: !isSvedClculation ? "100%": "10rem"}} className={colsOnly ? "styleForColumnsOnly" : "styleForTableHeader"}>
			<div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
				<DragIndicatorIcon style={{
					color: 'rgb(87, 87, 87)'
				}} fontSize="small" />
				<span
					className="boxText"
					style={{
						fontSize: "12px",
						fontFamily: "Roboto-Bold",
						color: `${isSvedClculation ? "rgb(87, 87, 87)" : ""}`,
						 maxWidth: "100px", // Set a fixed width
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
					}} >{name}
				</span>
				{
					isSvedClculation &&
					<AiOutlineFunction style={{ color: "#2BB9BB" }} size={15} />
				}
				{
				isSvedClculation &&
				<div
					style={{
						visibility: `${isDivHoverActive ? "visible" : "hidden"}`,
					}}
				>
					<button
						id="basic-button"
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={(e) => {
							if (arrowDirection === "down") {
								setArrowDirection("up")
							} else {
								setArrowDirection("down")
							}
							handleClick(e)
							// handleEditButton()
						}}
						style={{height: "100%", minWidth: "1rem", maxWidth: "1rem", backgroundColor: "transparent", border: "none"}}
					>
						{
							arrowDirection === "down" ?
								<MdOutlineKeyboardArrowDown style={{ color: "#2BB9BB" }} />
								:
								<MdOutlineKeyboardArrowUp style={{ color: "#2BB9BB" }} />
						}
					</button>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={() => {
							setIsDivHoverActive(false)
							handleClose()
						}}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
					>
						<MenuItem dense onClick={() => {
							setIsDivHoverActive(false)
							handleEditButton()
							handleClose()
						}}>Edit</MenuItem>
						<MenuItem dense onClick={() => {
							setIsDivHoverActive(false)
							handleDeleteButton()
							handleClose()
						}}>Delete</MenuItem>
					</Menu>
				</div>
			}
			</div>
			{/* {
				isSvedClculation &&
				<div
					style={{
						visibility: `${isDivHoverActive ? "visible" : "hidden"}`,
					}}
				>
					<Button
						id="basic-button"
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={(e) => {
							if (arrowDirection === "down") {
								setArrowDirection("up")
							} else {
								setArrowDirection("down")
							}
							handleClick(e)
							// handleEditButton()
						}}
					>
						{
							arrowDirection === "down" ?
								<MdOutlineKeyboardArrowDown style={{ color: "#2BB9BB" }} />
								:
								<MdOutlineKeyboardArrowUp style={{ color: "#2BB9BB" }} />
						}
					</Button>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={() => {
							setIsDivHoverActive(false)
							handleClose()
						}}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
					>
						<MenuItem dense onClick={() => {
							setIsDivHoverActive(false)
							handleEditButton()
							handleClose()
						}}>Edit</MenuItem>
						<MenuItem dense onClick={() => {
							setIsDivHoverActive(false)
							handleDeleteButton()
							handleClose()
						}}>Delete</MenuItem>
					</Menu>
				</div>
			} */}
		</div>
	);
};
