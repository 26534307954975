"use client";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./User.css";
import "./allPages.css";
import { Tooltip } from "@mui/material";
import axios from "axios";
import {serverEndPoint} from "./ServerCall/EnvironmentVariables";

interface User {
  id: string;
  firstName: string;
  email: string;
  isDeactivated: boolean;
  isDeleted: boolean;
  profileImage: string | null;
}

const Users = () => {
  const [users, setUsers] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState<any>(-1);
  const [groupName, setGroupName] = useState<any>("");
  const [showCreateGroupDialog, setShowCreateGroupDialog] =
    useState<any>(false);
  const [sortConfig, setSortConfig] = useState<any>({
    key: "firstName",
    direction: "asc",
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<any>(false);
  const [userIndexToDelete, setUserIndexToDelete] = useState<any>(null);
  const [showDeactivateConfirmation, setShowDeactivateConfirmation] =
    useState<any>(false);
  const [userIndexToDeactivate, setUserIndexToDeactivate] = useState<any>(null);
  const [showAlreadyDeactivatedDialog, setShowAlreadyDeactivatedDialog] =
    useState<any>(false);
  const [userToDelete, setUserToDelete] = useState<any>(null);
  const [userToDeactivate, setUserToDeactivate] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(true);
  const [selectAll, setSelectAll] = useState<boolean>(false); // Add state for Select All checkbox
  const [error, setError] = useState<string | null>(null);
  const [userState, setUserState] = useState<string | null>("deactivate");

  const accessToken = localStorage.getItem("accessToken");

  const axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "X-TENANT-ID": "silzila",
      "Content-type": "application/json",
    },
  });

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true); // Start loading state
      setError(null);
      try {
        // Fetch user list first
        const response = await axiosInstance.get(
          `${serverEndPoint}users/list`
        );

        // Prepare image requests for all users in parallel
        const usersWithProfileImages = response.data.map((user: any) => {
          if (!user.profileImage) {
            return { ...user, profileImage: "/default.png" };
          } else {
            const profileImageUrl = `data:image/jpeg;base64,${user.profileImage}`;
            return { ...user, profileImage: profileImageUrl };
          }
        });

        // Set the processed user list
        setUsers(usersWithProfileImages);
      } catch (error) {
        console.error("Error fetching users:", error);
        alert("Error fetching users. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const filterUsers = () => {
      const lowerCaseQuery = searchQuery.toLowerCase();
      return users.filter((user: any) => {
        const firstName = user.firstName?.toLowerCase() || "";
        const email = user.email?.toLowerCase() || "";
        return (
          firstName.includes(lowerCaseQuery) || email.includes(lowerCaseQuery)
        );
      });
    };

    const sortedAndFilteredUsers = () => {
      let filtered = filterUsers();
      filtered = [...filtered].filter((item) => item.isDeleted === false);

      if (sortConfig.key) {
        return filtered.sort((a: any, b: any) => {
          let aValue, bValue;
          if (sortConfig.key === "firstName") {
            aValue = a.firstName.toLowerCase() || "";
            bValue = b.firstName.toLowerCase() || "";
          } else if (sortConfig.key === "email") {
            aValue = a.email.toLowerCase() || "";
            bValue = b.email.toLowerCase() || "";
          } else if (sortConfig.key === "status") {
            aValue = a.isDeactivated ? 1 : 0;
            bValue = b.isDeactivated ? 1 : 0;
          }

          if (aValue < bValue) {
            return sortConfig.direction === "asc" ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === "asc" ? 1 : -1;
          }
          return 0;
        });
      }
      return filtered;
    };

    setFilteredUsers(sortedAndFilteredUsers());
  }, [searchQuery, users, sortConfig]);

  const deleteUser = (id: any) => {
    const user = users.find((user: any) => user.id === id);
    if (!user) {
      console.error("User not found");
      return;
    }
    setUserToDelete(user);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteUser = async () => {
    try {
      if (!userToDelete || !userToDelete.id)
        throw new Error("User ID is missing");

      await axiosInstance.delete(
        `${serverEndPoint}user/delete/${userToDelete.id}`
      );
      const updatedUsers = users.filter(
        (user: any) => user.id !== userToDelete.id
      );
      setUsers(updatedUsers);
    } catch (error) {
      console.error("There was an error deleting the user:", error);
      alert("Error deleting user. Please try again.");
    } finally {
      setShowDeleteConfirmation(false);
      setUserIndexToDelete(null);
    }
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setUserIndexToDelete(null);
  };

  const deactivateUser = (id: any, state: boolean = false) => {
    const userToDeactivate = users.find((user: any) => user.id === id);
    if (!userToDeactivate) {
      console.error("User not found.");
      return;
    }

    setUserState(state ? "activate" : "deactivate");

    // if (!userToDeactivate.isDeactivated) {
    setUserToDeactivate(userToDeactivate);
    setShowDeactivateConfirmation(true);
    // } else {
    //     //setShowAlreadyDeactivatedDialog(true);
    // }
  };

  const confirmDeactivateUser = async () => {
    try {
      if (!userToDeactivate.id) throw new Error("User ID is missing");

      let isActive = userState === "deactivate";

      await axiosInstance.put(
        `${serverEndPoint}user/deactivate?userId=${userToDeactivate.id}&deactivate=${isActive}`
      );
      setUsers((prevUsers: any) =>
        prevUsers.map((user: any) =>
          user.id === userToDeactivate.id
            ? { ...user, isDeactivated: isActive }
            : user
        )
      );
    } catch (error) {
      console.error("There was an error deactivating the user:", error);
      alert("Error deactivating user. Please try again.");
    } finally {
      setShowDeactivateConfirmation(false);
      setUserIndexToDeactivate(null);
    }
  };

  const cancelDeactivate = () => {
    setShowDeactivateConfirmation(false);
    setUserIndexToDeactivate(null);
  };

  const closeAlreadyDeactivatedDialog = () => {
    setShowAlreadyDeactivatedDialog(false);
  };

  const toggleSelectUser = (index: any) => {
    if (selectedUsers.includes(index)) {
      setSelectedUsers(selectedUsers.filter((i: any) => i !== index));
    } else {
      setSelectedUsers([...selectedUsers, index]);
    }
  };

  const selectAllUsers = () => {
    // Set the selected users to all the indexes of the filtered users
    setSelectedUsers(filteredUsers.map((_, index) => index)); // Here we're using `index` to track position in filteredUsers
    setSelectAll(true);
  };

  const handleDeselectAll = () => {
    setSelectedUsers([]);
    setSelectAll(false);
  };

  const handleMouseEnter = (index: any) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  const handleCreateGroup = () => {
    setShowCreateGroupDialog(true);
  };

  const handleGroupNameChange = (e: any) => {
    setGroupName(e.target.value);
  };

  const handleCloseDialog = () => {
    setShowCreateGroupDialog(false);
    setGroupName("");
  };

  const handleCreateGroupConfirm = async () => {
    const userIds = selectedUsers.map((index: any) => users[index].id);
    const requestBody = {
      userIds: userIds,
      groupName: groupName,
    };

    try {
      await axiosInstance.post(
        `${serverEndPoint}group-users/create`,
        requestBody
      );
      console.log(
        `Group "${groupName}" created successfully with users:`,
        userIds
      );
      setShowCreateGroupDialog(false);
      setGroupName("");
      setSelectedUsers([]);
    } catch (error) {
      console.error("Error creating group:", error);
      alert("Error creating group. Please try again.");
    }
  };

  const handleSort = (key: any) => {
    const newDirection =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction: newDirection });
  };

  const getSortIcon = (key: any) => {
    if (sortConfig.key === key) {
      if (key === "status") {
        return sortConfig.direction === "asc" ? (
          <img
            className="user-headerIcon"
            src="/status_activated.png"
            alt="Status Ascending"
            style={{ width: "17px", height: "17px" }}
          />
        ) : (
          <img
            className="user-headerIcon"
            src="/status_deactivated.png"
            alt="Status Descending"
            style={{ width: "17px", height: "17px" }}
          />
        );
      } else {
        return sortConfig.direction === "asc" ? (
          <img className="headerIcon-up" src="/sort-up.png" alt="Ascending" />
        ) : (
          <img
            className="headerIcon-down"
            src="/sort-down.png"
            alt="Descending"
          />
        );
      }
    }
    return <img className="headerIcon" src="/sort.png" alt="Default" />;
  };

  if (error)
    return (
      <div className="loading-error-info">
        <p className="loading-default-text">Error loading Users.</p>
      </div>
    );

  return (
    <div className="user-container">
      <div
        className="heading"
        style={{
          height: "50px",
          border: "1px solid transparent",
          display: "flex",
          marginLeft: "15px",
          paddingTop: "5px",
        }}
      >
        <img
          src="/user.png"
          alt="Users"
          style={{ marginLeft: "-2px", width: "24px", height: "24px" }}
        />
        <div>
          <h3
            style={{
              color: "#616164",
              marginTop: "-1px",
              marginBottom: "5px",
              marginLeft: "6px",
            }}
          >
            Users
          </h3>
        </div>
      </div>

      <div className="user-button-add-search-container">
        <div className="user-actionButtons">
          {selectedUsers.length === 0 ? (
            <Link to="/users/add" className="user-linkButton">
              <button className="user-button">
                {/* <img className='addIcon'src="/add_white.png" alt="Add Users Logo" width={20} height={20} /> */}
                <img
                  className="user-addIcon user-addIconDefault"
                  src="/add_green.png"
                  alt="Add Users Logo"
                  style={{ width: "16px", height: "16px", marginTop: "3px" }}
                />
                <img
                  className="user-addIcon user-addIconHover"
                  src="/add_white.png"
                  alt="Add Users Logo Hover"
                  style={{ width: "16px", height: "16px", marginTop: "3px" }}
                />
                Add Users
              </button>
            </Link>
          ) : (
            <button
              className="user-button1"
              onClick={handleCreateGroup}
              // style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
            >
              <img
                className="user-addIcon user-addIconDefault"
                src="/groups_green.png"
                alt="Create Group"
                style={{ width: "16px", height: "16px", marginTop: "3px" }}
              />
              <img
                className="user-addIcon user-addIconHover"
                src="/groups_white.png"
                alt="Create Group"
                style={{ width: "16px", height: "16px", marginTop: "3px" }}
              />
              Create Group
            </button>
          )}
        </div>

        <div className="user-select-action-container">
          <div className="user-actions-container">
            {/* Future Update
                    <p  onClick={selectAllUsers}>
                    Select All
                    </p>

                    {selectedUsers.length > 0 && (
                    <div className="user-actions-count-container">    
                    <p onClick={handleDeselectAll} style={{ cursor: 'pointer', marginLeft: "10px", border: "1px solid rgb(201, 201, 201)", }}>Deselect All</p>
                    <span>{selectedUsers.length > 0 && `${selectedUsers.length} selected`}</span>
                    <p style={{ marginLeft: "10px", border: "1px solid rgb(201, 201, 201)", }}>Actions</p>
                    </div>)} */}
          </div>

          <div className="user-searchContainer">
            <input
              type="text"
              placeholder="Search users"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="user-searchInput"
            />
            <img
              src="/glass.png"
              alt="Search Icon"
              className="user-searchIcon"
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="loading-container">
          <div className="user-spinner"></div>
        </div>
      ) : users.length === 0 && searchQuery.length === 0 ? (
        <div className="no-user-info">
          <p className="loading-default-text">
            You have not added any users in your organisation. Click here to{" "}
            <Link to="/users/add" className="link">
              {" "}
              Add Users
            </Link>
          </p>
        </div>
      ) : (
        // {users.length === 0 ? (
        //     <p className='default-text'>You have not added any users in your organisation. Click here to <Link className="link" to="/add-users">Add Users</Link></p>
        // ) : (
        <div className="user-tableContainer">
          <table className="user-userTable">
            <thead style={{ backgroundColor: "white" }}>
              <tr>
                <th></th>

                <th></th>

                <th onClick={() => handleSort("firstName")}>
                  Name{" "}
                  <span className="icon-wrapper">
                    {getSortIcon("firstName")}
                  </span>
                </th>

                <th onClick={() => handleSort("email")}>
                  Email{" "}
                  <span className="icon-wrapper">{getSortIcon("email")}</span>
                </th>

                <th onClick={() => handleSort("status")}>
                  Status{" "}
                  <span className="icon-wrapper">{getSortIcon("status")}</span>
                </th>

                <th style={{ paddingRight: "16px" }}>Actions</th>
              </tr>
            </thead>

                        <tbody>
                          {filteredUsers.length > 0 ? (
                            filteredUsers.map((user:any, index:any) => (
                                <tr
                                    key={index}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                    className={selectedUsers.includes(index) ? "selectedRow" : ""}>

                                    <td> 
                                        {/* <input 
                                            type="checkbox"
                                            checked={selectedUsers.includes(index)}
                                            onChange={() => toggleSelectUser(index)}
                                            style={{width: "16px", height: "16px"}}
                                        /> */}
                                    </td>

                    <td>
                      <img
                        src={
                          user.profileImage ? user.profileImage : "/default.png"
                        }
                        alt={`${user.firstName}`}
                        style={{ width: "20px", height: "20px", borderRadius: "50%" }}
                      />
                    </td>

                    <td>
                      {user.isAdmin ? (
                        <Tooltip title="Admin">
                          <img
                            src="/crown.png"
                            alt="Admin Icon"
                            style={{
                              transform: "translateY(3px)",
                              width: "15px",
                              height: "15px",
                              marginRight: "15px",
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <span
                          style={{
                            display: "inline-block",
                            width: "15px",
                            height: "15px",
                            marginRight: "15px",
                          }}
                        ></span>
                      )}
                      {user.firstName}
                    </td>

                    <td>{user.email}</td>

                    <td>
                      <div>
                        <Tooltip
                          title={
                            user.isDeactivated
                              ? "Deactivated User"
                              : "Active User"
                          }
                          arrow
                        >
                          <img
                            src={user.isDeactivated ? "/cross.png" : "/yes.png"}
                            alt={user.isDeactivated ? "Deactivated" : "Active"}
                            style={{ width: "17px", height: "17px" }}
                          />
                        </Tooltip>
                      </div>
                    </td>

                    <td>
                      <div className="user-img-icon">
                        <button
                          onClick={() => deleteUser(user.id)}
                          style={{ background: "none", border: "none" }}
                        >
                          <Tooltip title="Delete User">
                            <img
                              src={
                                hoveredIndex === index
                                  ? "/delete_red.png "
                                  : "/delete_white.png"
                              }
                              alt="Delete User"
                              style={{ width: "17px", height: "17px" }}
                            />
                          </Tooltip>
                        </button>

                        {user.isDeactivated ? (
                          <button
                            onClick={() => deactivateUser(user.id, true)}
                            style={{ background: "none", border: "none" }}
                          >
                            <Tooltip title="Activate User">
                              <img
                                src={
                                  hoveredIndex === index
                                    ? "/activate_user_active.png"
                                    : "/activate_user_inactive.png"
                                }
                                alt="Activate User"
                                style={{ width: "17px", height: "17px" }}
                              />
                            </Tooltip>
                          </button>
                        ) : (
                          <button
                            onClick={() => deactivateUser(user.id)}
                            style={{ background: "none", border: "none" }}
                          >
                            <Tooltip title="Deactivate User">
                              <img
                                src={
                                  hoveredIndex === index
                                    ? "/deactivate_orange.png"
                                    : "/deactivate_white.png"
                                }
                                alt="Deactivate User"
                                style={{ width: "17px", height: "17px" }}
                              />
                            </Tooltip>
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <div className="no-user-info">
                  <p className="loading-default-text">No user found.</p>
                </div>
              )}
            </tbody>
          </table>
        </div>
      )}
      {showCreateGroupDialog && (
        <div className="user-modal">
          <div className="user-modal-content">
            <span className="user-close" onClick={handleCloseDialog}>
              &times;
            </span>
            <p>Please name the group:</p>
            <input
              type="text"
              required
              value={groupName}
              onChange={handleGroupNameChange}
            />
            <div className="user-modal-buttons">
              <button onClick={handleCloseDialog} className="user-modal-cancel">
                Cancel
              </button>
              <button onClick={handleCreateGroupConfirm}>Create Group</button>
            </div>
          </div>
        </div>
      )}

      {showDeleteConfirmation && (
        <div className="user-modal">
          <div className="user-modal-content">
            <span className="user-close" onClick={cancelDelete}>
              &times;
            </span>
            <p>Are you sure you want to delete '{userToDelete?.firstName}' ?</p>
            <div className="user-modal-buttons">
              <button onClick={cancelDelete} className="user-modal-cancel">
                Cancel
              </button>
              <button
                onClick={confirmDeleteUser}
                className="user-modal-confirm"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showDeactivateConfirmation && (
        <div className="user-modal">
          <div className="user-modal-content">
            <span className="user-close" onClick={cancelDeactivate}>
              &times;
            </span>
            <p>
              Are you sure you want to {userState} '
              {userToDeactivate?.firstName}' ?
            </p>
            <div className="user-modal-buttons">
              <button onClick={cancelDeactivate} className="user-modal-cancel">
                Cancel
              </button>
              <button
                onClick={confirmDeactivateUser}
                className={`user-modal-confirm-${userState}`}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showAlreadyDeactivatedDialog && (
        <div className="user-modal">
          <div className="user-modal-content">
            <span
              className="user-close"
              onClick={closeAlreadyDeactivatedDialog}
            >
              &times;
            </span>
            <p>This user is already deactivated.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
