import React from 'react';
import Header from "./../Components/Header"
import { Link } from 'react-router-dom';

const AccessRestricted = () => {
    return (
        <div>
            <Header/>
            <div style={{ textAlign: 'center', marginTop: '175px' }}>
                <img src="/restricted_access.png" style={{width: "100px", height: "100px"}}></img>
                <h1 style={{ color:"#616164" }}>Access Restricted!</h1>
                <p style={{ color:"#616164", marginTop:"50px" }}>We're sorry, but you do not have permission to access this resource.</p>
                <p style={{ color:"#616164" }}>If you believe this is a mistake or you require access, please contact our support team.</p>
                <Link to="/" style={{ color:"#616164", fontSize:'14px'}}>Back to Homepage</Link>
            </div>
        </div>
    );
};

export default AccessRestricted;